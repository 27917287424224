export const renderConditionsFr = `<p style="margin-top:0pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:11.5pt;"><strong>CONDITIONS G&Eacute;N&Eacute;RALES D&rsquo;UTILISATION PLATEFORME DE VOLONTARIAT</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:11.5pt;"><strong>Termes et conditions</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 1er : Objet&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation encadrent juridiquement l&rsquo;acc&egrave;s et l&rsquo;utilisation du site https://volunteeringplatform.org/ (ci-apr&egrave;s d&eacute;nomm&eacute; &laquo; la Plateforme de volontariat &raquo; ou &laquo; Plateforme &raquo;), d&rsquo;application mobile ou services (ci-apr&egrave;s d&eacute;nomm&eacute;s &laquo; les Services &raquo;) fournis par Travel With A Mission (ci-apr&egrave;s d&eacute;nomm&eacute; TWAM).&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Constituant le contrat entre la Plateforme de volontariat et l&rsquo;Utilisateur, l&rsquo;acc&egrave;s au site doit &ecirc;tre proc&eacute;d&eacute; de l&rsquo;acceptation de ces Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">En cliquant sur &laquo; J&rsquo;accepte &raquo; ou en vous inscrivant pour utiliser nos Services, vous acceptez d&rsquo;&ecirc;tre li&eacute; par les-dites conditions.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 2 : Contenu&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">La Plateforme de volontariat offre un outil en ligne permettant aux utilisateurs (ci-apr&egrave;s d&eacute;nomm&eacute; &laquo; Membres &raquo;) de publier, de proposer, de rechercher et de r&eacute;server des Services.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les Membres qui publient et proposent des Services sont des &laquo; H&ocirc;tes &raquo; et les Membres qui proposent ou utilisent des Services sont des &laquo; Volontaires &raquo;.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les H&ocirc;tes offrent des Missions solidaires (ci-apr&egrave;s d&eacute;nomm&eacute; &laquo; Mission &raquo; ou &laquo; Mission solidaire &raquo;) avec la possibilit&eacute; d&rsquo;y assimiler un ensemble de prestations (ci-apr&egrave;s d&eacute;nomm&eacute; &laquo; Package &raquo;).&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les Missions solidaires peuvent &ecirc;tre diverses et propos&eacute;es aux Voyageurs &agrave; titre gratuit ou on&eacute;reux.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Package propos&eacute; par l&rsquo;H&ocirc;te est un ensemble de services qui sont compris dans la Mission. Il peut &ecirc;tre compos&eacute; d&rsquo;un h&eacute;bergement, de nourriture, de transport local, diverses activit&eacute;s et formations. En addition, il est possible de proposer des options pour un co&ucirc;t suppl&eacute;mentaire &agrave; la charge du Volontaire.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les Volontaires r&eacute;servent des Missions solidaires avec la possibilit&eacute; de choisir les dates du s&eacute;jour en accord avec les H&ocirc;tes.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les Volontaires doivent r&eacute;server leur Mission pr&eacute;c&eacute;dent un d&eacute;lai avant de partir, ce dernier &eacute;tant indiqu&eacute; par les H&ocirc;tes dans l&rsquo;annonce.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">En parall&egrave;le de ces conditions g&eacute;n&eacute;rales, TWAM applique &eacute;galement des Conditions et Politiques compl&eacute;mentaires ; la Politique de confidentialit&eacute;, qui d&eacute;crit la collecte et l&rsquo;utilisation des donn&eacute;es personnelles de la Plateforme de volontariat, et les conditions de paiement qui r&eacute;gissent tous les services de paiement fournis aux Membres sur la Plateforme de volontariat.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 3 : Mentions l&eacute;gales&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;&eacute;dition de la Plateforme de volontariat est assur&eacute;e par l&rsquo;association Travel With A Mission, enregistr&eacute;e &agrave; Nice sous le num&eacute;ro W062006565, SIRET 79071733400029, dont le si&egrave;ge social est localis&eacute; au 945 Boulevard du Mar&eacute;chal Leclerc, 06360 &Egrave;ze, France.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">TWAM est une association &agrave; but non lucratif conform&eacute;ment constitu&eacute;e &agrave; la loi fran&ccedil;aise du 1er juillet 1901 relative au contrat d&rsquo;association.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Bien que les informations publi&eacute;es sur la Plateforme soient r&eacute;put&eacute;es fiables, TWAM n&rsquo;est pas responsable des informations publi&eacute;es par les H&ocirc;tes concernant les missions solidaires propos&eacute;es.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Ces derniers demeurent responsables du caract&egrave;re pr&eacute;cis, complet et exact des informations qui les concernent, ainsi que celles qui sont publi&eacute;es sur la Plateforme de volontariat.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">TWAM ne propose aucune aide financi&egrave;re pour payer les Missions solidaires.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Dans le cadre de la plateforme du volontariat, TWAM joue un r&ocirc;le de facilitateur, mettant en relation une offre et une demande (cf. un volontaire et une structure d&rsquo;accueil).</span></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Package que l&rsquo;H&ocirc;te propose est cependant contractuel, d&egrave;s lors que ce dernier accepte une Mission d&apos;un volontaire.&nbsp;</span></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation font partie int&eacute;grale du contrat de volontariat sign&eacute; entre le Volontaire et l&rsquo;H&ocirc;te. Elles doivent &ecirc;tre lues conjointement avec le formulaire d&rsquo;inscription.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les H&ocirc;tes doivent comprendre et respecter l&rsquo;ensemble des lois, r&egrave;glements, r&eacute;glementations et contrats avec des tiers qui s&apos;appliquent aux Services.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 4 : Acc&egrave;s au site&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">La Plateforme de volontariat permet d&rsquo;acc&eacute;der gratuitement aux Services suivants :&nbsp;</span></p>
<ul type="disc" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left:46.29pt; line-height:normal; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Publication de Missions solidaires ;&nbsp;</span></li>
    <li style="margin-left:46.29pt; line-height:normal; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Proposition de Missions solidaires ;&nbsp;</span></li>
    <li style="margin-left:46.29pt; line-height:normal; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Consultation de Missions solidaires ;&nbsp;</span></li>
    <li style="margin-left:46.29pt; line-height:normal; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Recherche de Missions solidaires ;&nbsp;</span></li>
    <li style="margin-left:46.29pt; line-height:normal; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">R&eacute;servation de Missions solidaires.&nbsp;</span></li>
</ul>
<p style="margin-top:0pt; margin-bottom:0pt; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">La Plateforme de volontariat est accessible &agrave; titre gratuit depuis n&rsquo;importe o&ugrave;, par tout utilisateur disposant d&rsquo;un acc&egrave;s internet.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Tous les frais n&eacute;cessaires &agrave; l&rsquo;acc&egrave;s de ces Services sont &agrave; la charge de l&rsquo;utilisateur. Cela comprend le mat&eacute;riel informatique, la connexion internet, etc.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Pour &ecirc;tre membre, il est n&eacute;cessaire de</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">:&nbsp;</span></p>
<ul type="disc" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left:28.29pt; text-align:justify; line-height:108%; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Cr&eacute;er un compte, il permettra d&rsquo;acc&eacute;der et utiliser de nombreuses fonctionnalit&eacute;s que la Plateforme de volontariat propose.&nbsp;</span></li>
    <li style="margin-left:28.29pt; margin-bottom:8pt; text-align:justify; line-height:108%; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Veiller &agrave; ce que les informations de votre compte demeurent exactes. L&rsquo;acc&egrave;s aux Services d&eacute;di&eacute;s aux Membres s&rsquo;effectue &agrave; l&rsquo;aide d&rsquo;un identifiant et d&rsquo;un mot de passe.&nbsp;</span></li>
</ul>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les Membres s&rsquo;engagent &agrave; ne cr&eacute;er qu&rsquo;un seul compte personnel et ne pas cr&eacute;er de compte pour autrui sans autorisation.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Si les Membres utilisent les Services au nom d&rsquo;une entit&eacute;, ils garantissent &ecirc;tre d&ucirc;ment autoris&eacute; &agrave; accepter les pr&eacute;sentes conditions au nom de celle-ci.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Cette entit&eacute; sera responsable de la possible violation des pr&eacute;sentes conditions.&nbsp;</span></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">En raison de maintenance ou autre op&eacute;ration d&rsquo;entretien de la Plateforme, l&rsquo;acc&egrave;s &agrave; celle-ci peut &ecirc;tre interrompu ou suspendu par TWAM sans pr&eacute;avis ni justification.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Conditions relatives aux Volontaires</span></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 5 : Responsabilit&eacute;s du volontaire sur l&rsquo;utilisation de la Plateforme&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire garantit avoir minimum 18 ans au moment de son inscription.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire s&rsquo;engage &agrave; fournir des informations personnelles exactes. Ce dernier est garant de la mise &agrave; jour des donn&eacute;es le concernant lui-m&ecirc;me ou l&rsquo;entit&eacute; qu&rsquo;il repr&eacute;sente et celles concernant les Missions solidaires qu&rsquo;il propose.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">La Plateforme de volontariat offre la possibilit&eacute; de publier des commentaires, des publications, de partager des vid&eacute;os et photos. Tout ce contenu est public et peut &ecirc;tre accessible par les autres utilisateurs, m&ecirc;me non Membres.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire assume enti&egrave;rement la responsabilit&eacute; de toutes les activit&eacute;s qui se produisent sous son compte en tant que Membre. Ainsi, il accepte les risques de tout acc&egrave;s autoris&eacute; ou non.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 6 : Responsabilit&eacute; du volontaire lors des Missions solidaires&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>6.1. &Eacute;ligibilit&eacute; pour partir en Mission solidaire</strong></p>
<ul type="disc" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left:28.29pt; text-align:justify; line-height:108%; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire doit avoir rempli correctement le formulaire d&rsquo;inscription en ligne, en indiquant toutes les informations obligatoires et jug&eacute;es n&eacute;cessaires afin de pr&eacute;parer au mieux la mission dans le pays &eacute;tranger.&nbsp;</span></li>
    <li style="margin-left:28.29pt; text-align:justify; line-height:108%; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire garantie l&rsquo;exactitude de ses informations fournies aupr&egrave;s de l&rsquo;H&ocirc;te.&nbsp;</span></li>
    <li style="margin-left:28.29pt; text-align:justify; line-height:108%; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire ne doit pas avoir de contre-indication m&eacute;dicale.&nbsp;</span></li>
    <li style="margin-left:28.29pt; margin-bottom:8pt; text-align:justify; line-height:108%; padding-left:7.71pt; font-family:serif; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire ne doit pas avoir &eacute;t&eacute; condamn&eacute; pour crime ou &agrave; une peine d&rsquo;au moins deux mois d&rsquo;emprisonnement sans sursis pour un d&eacute;lit pr&eacute;vu dans la liste de l&rsquo;article L133-6 du Code de l&rsquo;action social et des familles fran&ccedil;ais.&nbsp;</span></li>
</ul>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Sauf condition requise par l&rsquo;H&ocirc;te, TWAM ne fixe ni d&rsquo;&acirc;ge minimum, ni d&rsquo;&acirc;ge maximum.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Toute personne peut partir faire une Mission solidaire si elle r&eacute;pond aux conditions pr&eacute;alables &eacute;tablies par l&rsquo;H&ocirc;te.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Avec accord de l&rsquo;H&ocirc;te, les enfants, accompagn&eacute;s de leurs parents, ainsi que les personnes retrait&eacute;es peuvent faire une Mission solidaire.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Sauf condition requise par l&rsquo;H&ocirc;te, TWAM ne met aucune restriction concernant la nationalit&eacute; du Volontaire.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Cependant, il est rappel&eacute; que tous les Volontaires doivent &ecirc;tre en ordre au niveau de leurs visas et vaccins pour pouvoir entrer dans le pays l&eacute;galement.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Sauf condition requise par l&rsquo;H&ocirc;te, le volontaire n&rsquo;a pas besoin d&rsquo;avoir de comp&eacute;tences particuli&egrave;res pour effectuer une Mission solidaire. Aucun niveau de langue n&rsquo;est demand&eacute;.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Lors de la r&eacute;servation d&rsquo;une Mission solidaire, les papiers d&rsquo;identit&eacute; du Volontaire et tous documents n&eacute;cessaires seront obligatoirement fournis &agrave; TWAM et &agrave; l&rsquo;H&ocirc;te.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>6.2. Responsabilit&eacute; du volontaire avant la Mission solidaire&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire doit avoir rempli correctement le formulaire d&rsquo;inscription en ligne, en indiquant toutes les informations obligatoires le concernant.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Une fois la r&eacute;servation finalis&eacute;e, il est de la responsabilit&eacute; du Volontaire de fournir &agrave; l&rsquo;H&ocirc;te le d&eacute;tail de ses conditions d&rsquo;arriv&eacute;e (billet de train, d&rsquo;avion, etc.) de fa&ccedil;on &agrave; ce que l&rsquo;H&ocirc;te puisse honorer sa part du contrat (accueil, etc.).</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Il est demand&eacute; au Volontaire de se renseigner et d&rsquo;effectuer le ou les vaccins obligatoires aupr&egrave;s de son m&eacute;decin traitant ou aupr&egrave;s du centre de vaccination pr&egrave;s de chez soi pour les pays &eacute;trangers.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Il est demand&eacute; au Volontaire de respecter les conditions d&rsquo;entr&eacute;e dans le pays (&eacute;ventuel visa, etc.).</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>6.3. Responsabilit&eacute; du volontaire durant la Mission solidaire&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">D&egrave;s lors la r&eacute;servation et le paiement de la Mission solidaire effectu&eacute;s, les engagements entre le Volontaire et l&rsquo;H&ocirc;te deviennent contractuels.&nbsp;</span></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire doit respecter toutes les r&egrave;gles &eacute;tablies pr&eacute;alablement par l&rsquo;H&ocirc;te.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire doit avoir un comportement exemplaire sur le lieu de la Mission solidaire durant tout le s&eacute;jour.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire s&rsquo;engage &eacute;galement &agrave; respecter les lois du pays de la Mission solidaire. Si le pays d&rsquo;accueil est sujet &agrave; des traditions ou cultures particuli&egrave;res, le Volontaire est responsable de s&rsquo;y adapter.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Conditions relatives aux H&ocirc;tes</span></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 7 : Responsabilit&eacute; de l&rsquo;H&ocirc;te lors des Missions solidaires&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><em><span style="font-family:'AAAAAE+TimesNewRomanPS-BoldItal';">7.1. Responsabilit&eacute; de l&rsquo;H&ocirc;te avant la Mission solidaire&nbsp;</span></em></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;H&ocirc;te doit avoir rempli correctement le formulaire d&rsquo;inscription en ligne, en indiquant toutes les informations obligatoires et jug&eacute;es n&eacute;cessaires afin de pr&eacute;parer au mieux l&rsquo;arriv&eacute;e du Volontaire dans le lieu de la Mission solidaire.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;H&ocirc;te devra fournir &agrave; TWAM les documents de reconnaissance de la structure.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;H&ocirc;te garantit l&rsquo;exactitude de ses informations fournies aupr&egrave;s du volontaire.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;H&ocirc;te a la possibilit&eacute; sur la plateforme de mettre en place des restrictions pour les personnes qui souhaiteraient faire les Missions qu&rsquo;il propose. Ces restrictions peuvent concerner l&rsquo;&acirc;ge, la nationalit&eacute;, les comp&eacute;tences, la langue, la condition physique, etc.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;H&ocirc;te d&eacute;cide du contenu de son package et du prix associ&eacute; que devra payer le volontaire avant le d&eacute;but de la mission.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">La Mission solidaire peut &ecirc;tre &eacute;galement gratuite si l&rsquo;H&ocirc;te en d&eacute;cide ainsi. Le package contractuel reste cependant valide, malgr&eacute; la gratuit&eacute;.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;H&ocirc;te se r&eacute;serve le droit de refuser un Volontaire si ce dernier ne correspond pas au profil recherch&eacute;.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Apr&egrave;s la r&eacute;servation d&rsquo;une Mission solidaire par le Volontaire, l&rsquo;H&ocirc;te dispose d&rsquo;un d&eacute;lai de 48 heures pour r&eacute;pondre par la positive ou la n&eacute;gative.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Une fois la mission accept&eacute;e, l&rsquo;H&ocirc;te devra respecter le package devenant contrat. En cas de non-respect, il s&rsquo;exposerait &agrave; des sanctions (remboursement du billet d&rsquo;avion du volontaire, etc.) au cas o&ugrave; le Volontaire devait &laquo;</span></strong><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></strong><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">soulever un diff&eacute;rend</span></strong><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></strong><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&raquo; en faisant appel &agrave; la m&eacute;diation de TWAM.</span></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;H&ocirc;te se tient garant de la bonne prise en charge du volontaire dans le cadre de la r&eacute;alisation de la Mission solidaire.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>7.2. Responsabilit&eacute; de l&rsquo;H&ocirc;te durant la Mission solidaire&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">D&egrave;s lors que la r&eacute;servation et le paiement de la Mission sont effectu&eacute;s, les engagements entre le Volontaire et l&rsquo;H&ocirc;te deviennent contractuels.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;H&ocirc;te est donc dans l&rsquo;obligation de r&eacute;aliser l&rsquo;int&eacute;gralit&eacute; des &eacute;l&eacute;ments propos&eacute;s dans le Package. Dans le cas contraire, le volontaire pourra &laquo;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">soulever un diff&eacute;rend</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&raquo; en passant par la m&eacute;diation de TWAM afin de tenter d&rsquo;obtenir r&eacute;paration.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; line-height:108%; font-size:11.5pt;"><strong>Article 8 :</strong><strong><em><span style="font-family:'AAAAAE+TimesNewRomanPS-BoldItal';">&nbsp;</span></em></strong><strong>Modification, annulation, r&eacute;siliation et rupture de contrat</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">D&egrave;s lors que la r&eacute;servation et le paiement de la Mission sont effectu&eacute;s, les engagements entre le Volontaire et l&rsquo;H&ocirc;te deviennent contractuels.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Cependant</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">:&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">-</span><span style="width:14.18pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le volontaire a la possibilit&eacute; de r&eacute;silier &agrave; tout moment par &eacute;crit (courrier ou mail) le contrat avec l&rsquo;H&ocirc;te.&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:8pt; text-indent:-18pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">-</span><span style="width:14.18pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">L&rsquo;H&ocirc;te a la possibilit&eacute; de r&eacute;silier &agrave; tout moment par &eacute;crit (courrier ou mail) le contrat avec la Volontaire.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Cela pour des raisons qui leur seraient propres.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Si cela se produit, 2 solutions peuvent se pr&eacute;senter</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">:</span></p>
<ol type="1" class="awlist1" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left:54pt; text-indent:-18pt; text-align:justify; line-height:108%; font-family:'AAAAAC+TimesNewRomanPSMT'; font-size:11.5pt;"><span style="width:7.24pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Les 2 parties s&rsquo;entendent sur une modification de programme ou/et un &eacute;ventuel remboursement d&rsquo;une partie ou de la totalit&eacute; de la somme. Ils en informent TWAM en &eacute;crivant &agrave; l&rsquo;adresse info@travelwithamission.org,</li>
    <li style="margin-left:54pt; margin-bottom:8pt; text-indent:-18pt; text-align:justify; line-height:108%; font-family:'AAAAAC+TimesNewRomanPSMT'; font-size:11.5pt;"><span style="width:7.24pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Les 2 parties ne s&rsquo;entendent pas sur une modification de programme ou/et un &eacute;ventuel remboursement d&rsquo;une partie ou de la totalit&eacute; de la somme. Ils peuvent &laquo;&nbsp;soulever un diff&eacute;rend&nbsp;&raquo; en exposant chacun ses arguments &agrave; TWAM qui jouera le r&ocirc;le de m&eacute;diateur et d&eacute;cidera de la suite &agrave; donner.</li>
</ol>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 9 : D&eacute;charge de responsabilit&eacute; de TWAM&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Dans le cadre de la plateforme du volontariat,&nbsp;</span><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">TWAM se positionne en tant que facilitateur et non organisateur</span></strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;(voir&nbsp;</span><a href="https://volontariat.travelwithamission.org" style="text-decoration:none;"><u><span style="font-family:'AAAAAC+TimesNewRomanPSMT'; color:#0563c1;">https://volontariat.travelwithamission.org</span></u></a><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;pour le volontariat encadr&eacute; et le positionnement &laquo;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">organisateur</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&raquo;).&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Cependant, afin de favoriser la s&eacute;curit&eacute;, TWAM demande aux structures locales les papiers officiels de leur structure, les papiers d&rsquo;identit&eacute; des Membres et joue le r&ocirc;le de m&eacute;diateur lorsqu&rsquo;il y a un litige.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">TWAM ne saurait cependant &ecirc;tre tenu responsable</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">:</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">-</span><span style="width:14.18pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Du contenu des profils, missions et packages propos&eacute;s par les Membres,</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">-</span><span style="width:14.18pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">De circonstances diverses et vari&eacute;es qui entra&icirc;neraient des modifications ou annulations du s&eacute;jour (ex. Maladie, changement d&rsquo;avis, gr&egrave;ve, trouble de la paix publique, incendie, inondation, condition m&eacute;t&eacute;orologique, r&eacute;volte civile, guerre, acte de terrorisme, op&eacute;ration militaire, invasion, r&eacute;bellion, pand&eacute;mie, &eacute;ruption volcanique, maladie, vol qualifi&eacute;, vol, boycott, arrestation, dysfonctionnement technique, coupures de courant, panne de v&eacute;hicules, etc.),</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">-</span><span style="width:14.18pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Des diff&eacute;rents ph&eacute;nom&egrave;nes climatiques dont pourrait &ecirc;tre victime le Volontaire pendant sa mission, comprenant les inondations, brouillard, incendies, etc.,</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:8pt; text-indent:-18pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">-</span><span style="width:14.18pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Des dommages caus&eacute;s au volontaire &agrave; la suite de chutes, accidents, maladies, hospitalisation, d&eacute;penses m&eacute;dicales, dommages aux bagages, d&eacute;faut d&rsquo;ex&eacute;cution de certaines actions ou mauvaise ex&eacute;cution, d&eacute;sagr&eacute;ment ou tout dommage direct ou indirect pouvant &ecirc;tre caus&eacute; au Volontaire pendant le s&eacute;jour.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">TWAM sera donc exon&eacute;r&eacute; de toute responsabilit&eacute; pour une annulation/modification de mission et ne prendra en charge aucun frais li&eacute;s &agrave; d&rsquo;&eacute;ventuels changements ou accidents.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Le Volontaire est lui-m&ecirc;me responsable de souscrire une assurance voyage tous risques pouvant rembourser l&rsquo;annulation de la totalit&eacute; des frais engag&eacute;s pour la Mission et permettant de couvrir des dommages caus&eacute;s au volontaire &agrave; la suite de chutes, accidents, maladies, hospitalisation, d&eacute;penses m&eacute;dicales, dommages aux bagages, d&eacute;faut d&rsquo;ex&eacute;cution de certaines actions ou mauvaise ex&eacute;cution, d&eacute;sagr&eacute;ment ou tout dommage direct ou indirect pouvant &ecirc;tre caus&eacute; au Volontaire pendant le s&eacute;jour.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Gr&acirc;ce &agrave; des partenariats, TWAM propose des r&eacute;ductions sur ces assurances (</span><a href="https://soutien.travelwithamission.org/reductions/" style="text-decoration:none;"><u><span style="font-family:'AAAAAC+TimesNewRomanPSMT'; color:#0563c1;">https://soutien.travelwithamission.org/reductions/</span></u></a><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">).&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><em><span style="font-family:'AAAAAE+TimesNewRomanPS-BoldItal';">&nbsp;</span></em></strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 10 : Juridiction&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les parties acceptent que ces termes et conditions soient r&eacute;gis et interpr&eacute;t&eacute;s conform&eacute;ment &agrave; la loi fran&ccedil;aise.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">En cas de litige quelconque en relation avec ces Conditions ou le Contrat de volontariat, les parties attribuent une comp&eacute;tence exclusive aux juridictions de la ville de Nice (France).</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 11 : Droit &agrave; l&rsquo;image&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Afin de mettre en valeur l&rsquo;impact positif des Volontaires aupr&egrave;s des communaut&eacute;s locales, TWAM met en avant les photos des Missions r&eacute;alis&eacute;es par ses Volontaires. Si le Volontaire ne souhaite pas que des photos soient diffus&eacute;es, il suffit d&rsquo;en faire une simple demande par &eacute;crit.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Si l&rsquo;H&ocirc;te souhaite utiliser les photos prises lors du s&eacute;jour pour mettre en avant ses activit&eacute;s et que le Volontaire veut r&eacute;pondre de son droit &agrave; l&rsquo;image, ce dernier devra en avertir l&rsquo;H&ocirc;te.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 12 : Collecte des donn&eacute;es&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Pour la cr&eacute;ation du compte membre, la collecte des informations au moment de l&rsquo;inscription sur le site est n&eacute;cessaire et obligatoire. Conform&eacute;ment &agrave; la loi fran&ccedil;aise n&deg;78-17 du 6 janvier 1978 modifi&eacute;e relative &agrave; l&rsquo;informatique, aux fichiers et aux libert&eacute;s, la collecte et le traitement d&rsquo;informations personnelles s&rsquo;effectuent dans le respect de la vie priv&eacute;e. En application des articles 39 et suivants de la-dite loi, les utilisateurs b&eacute;n&eacute;ficient d&rsquo;un droit d&rsquo;acc&egrave;s et de rectification aux informations qui les concernent.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong>Article 13 : Protection intellectuelle&nbsp;</strong></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Les marques, les logos et les contenus de la Plateforme de volontariat ; comprenant les illustrations, les graphiques et les textes sont la propri&eacute;t&eacute; exclusive de TWAM et de ses prestataires de service. La propri&eacute;t&eacute; de TWAM est prot&eacute;g&eacute;e par le Code de la propri&eacute;t&eacute; intellectuelle et par le droit d&rsquo;auteur. Par la protection du Code de la propri&eacute;t&eacute; intellectuelle et le droit d&rsquo;auteur, la reproduction et la copie des contenus par les utilisateurs requi&egrave;rent une autorisation pr&eacute;alable du site. De ce fait, toute utilisation &agrave; des usages commerciaux ou &agrave; des fins publicitaires est proscrite. Toute utilisation ill&eacute;gale ou action susmentionn&eacute;e constituera une violation substantielle des droits de propri&eacute;t&eacute; intellectuelle de TWAM.&nbsp;</span></p>`
