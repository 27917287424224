import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, ReactReduxContext } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import 'translates/translate'

/* store */
import { store, history } from 'store'

/* application */
import App from 'App'

/* env */
import { ENV } from 'constants/main'

/* styles */
import 'styles/bootstrap.scss'
import 'styles/index.scss'

// TODO remove after all old svgs will be deleted
import { ReactComponent as Sprite } from 'assets/sprite.svg'

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <Sprite />
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
)

if (ENV === 'development' && module.hot) {
  module.hot.accept()
}
