import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo } from 'react'
import { SET_LANGUAGE } from '../modules/currentLanguage/store/constants'
import { modifyVolunteerProfileInfoAction } from '../modules/volunteer/store/actions'
import { getLanguagesAction } from '../modules/currentLanguage/store/actions'
import { syncCurrentUserAction } from '../modules/currentUser/store/actions'

export function useLanguage() {
  const dispatch = useDispatch()
  const {
    languages: languagesInState,
    currentUser,
  } = useSelector(({
    languages,
    currentUser,
  }) => ({
    languages,
    currentUser,
  }))

  useEffect(() => {
    if (!(languagesInState.languages.length > 0)) getLanguagesAction()(dispatch) // eslint-disable-next-line
  }, [])

  const languages = useMemo(() => (languagesInState.languages.length ? languagesInState.languages : [
    {
      id: 1,
      value: 1,
      label: 'English',
      code: 'en',
    },
    {
      id: 2,
      value: 2,
      label: 'French',
      code: 'fr',
    }, {
      id: 3,
      value: 3,
      label: 'German',
      code: 'de',
    },
  ]), [languagesInState])

  const currentLanguage = useMemo(() => {
    if (currentUser.profile?.language) {
      return {
        ...currentUser.profile.language,
        label: currentUser.profile.language.name,
        value: currentUser.profile.language.id,
      }
    }
    return languagesInState.currentLanguage
  }, [currentUser.profile, languagesInState.currentLanguage])

  const setLanguage = useCallback(
    (lang) => {
      if (currentUser.user?.userId && currentUser.profile?.profileId) {
        modifyVolunteerProfileInfoAction(currentUser.user.userId, {
          language_id: lang.value, // eslint-disable-next-line
          phone_number_show: currentUser?.profile?.phone_number_show || false,
        })().then(() => {
          syncCurrentUserAction()(dispatch)
        })
      }
      dispatch({
        type: SET_LANGUAGE,
        payload: lang,
      })
    }, // eslint-disable-next-line
    [currentUser.profile?.phone_number_show, currentUser.profile?.profileId,currentUser.user?.userId, dispatch],
  )

  return {
    languages,
    currentLanguage,
    setLanguage,
  }
}
