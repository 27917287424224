import { toast } from 'react-toastify'
import { objToFormData } from 'utils/object'
import { SET_CURRENT_USER, SET_CURRENT_USER_PROFILE, SET_CURRENT_USER_AND_PROFILE } from './constants'
import CurrentUserService from '../CurrentUserService'
import AuthService from '../../auth/AuthService'

export const getCurrentUserAction = () => async (dispatch) => {
  const currentUser = await CurrentUserService.getCurrentUser()

  dispatch({
    type: SET_CURRENT_USER,
    payload: currentUser,
  })

  return currentUser
}

export const syncProfileAction = () => async (dispatch, getState) => {
  const { currentUser: { user: { userId } } } = getState()

  if (!userId) {
    return undefined
  }

  const profile = await CurrentUserService.getProfileByUserId(userId)

  dispatch(
    {
      type: SET_CURRENT_USER_PROFILE,
      payload: profile,
    },
  )

  return profile
}

export const syncCurrentUserAction = () => async (dispatch) => {
  const payload = {}
  payload.user = await CurrentUserService.getCurrentUser()

  if (payload.user.isProfileFilled) {
    payload.profile = await CurrentUserService.getProfileByUserId(payload.user.userId)
  }

  dispatch({
    type: SET_CURRENT_USER_AND_PROFILE,
    payload,
  })

  return payload
}

export const createProfileAction = (data) => async (dispatch) => {
  try {
    /* after filling in the profile, get a new token */
    const { authorization } = await CurrentUserService.createProfile(objToFormData({ data })).then(({ result }) => result)
    AuthService.setToken(`${authorization.token_type} ${authorization.access_token}`)
    toast.success('Profile Successfully Created!')
    await dispatch(syncCurrentUserAction())
  } catch (error) {
    toast.error('Error creating profile')
  }
}

export const verifyAccountAction = (data) => async () => {
  const data1 = objToFormData({ data })
  return CurrentUserService.verifyAccount(data1)
}
