export const ENV = process.env.REACT_APP_ENV

export const API_URL = process.env.REACT_APP_API_URL

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL

export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID

export const CHAT_URL = process.env.REACT_APP_CHAT_URL
export const MAIN_URL = 'https://volunteeringplatform.org'
