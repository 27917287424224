export const getOptionCost = ({ calcOption: { cost } }) => {
  if (!cost) {
    return false
  }

  return cost
}

export const setBook = ({ calcOption: { booking, isModalOpen } }) => {
  if (!booking) {
    return false
  }

  return { ...booking, isModalOpen }
}

export const getRequests = ({ calcOption: { requests } }) => {
  if (!requests) {
    return false
  }

  return requests
}

export const getVolunteers = ({ calcOption: { volunteers } }) => {
  if (!volunteers) {
    return false
  }

  return volunteers
}
