import { baseRequestHandler, volunteeringRequestHandler } from '../../api/http-client/request-handler'

const tokenKey = 'authToken'

const AuthService = {
  getToken() {
    return localStorage.getItem(tokenKey)
  },

  setToken(token) {
    if (!token) {
      return localStorage.removeItem(tokenKey)
    }

    return localStorage.setItem(tokenKey, token)
  },

  hasToken() {
    return !!this.getToken()
  },

  async signUp(formData) {
    const { authorization } = await volunteeringRequestHandler.post('register', formData)
      .then(({ result }) => result)
    this.setToken(`${authorization.token_type} ${authorization.access_token}`)
  },

  async signIn(formData) {
    const { authorization } = await volunteeringRequestHandler.post('login', formData).then(({ result }) => result)
    this.setToken(`${authorization.token_type} ${authorization.access_token}`)
  },

  signOut() {
    this.setToken()
  },

  forgotPassword(formData) {
    const platform = 'volunteering'
    return baseRequestHandler.post('forgot-password', { ...formData, platform })
  },

  resetPassword(formData) {
    return baseRequestHandler.post('reset-password', formData)
  },

  canResetPassword(email) {
    return baseRequestHandler.post('reset-password-test', email)
  },

  async facebookAuth(formData) {
    const { authorization } = await volunteeringRequestHandler.post('social/login', formData).then(({ result }) => result)
    this.setToken(`${authorization.token_type} ${authorization.access_token}`)
  },
}

export default AuthService
