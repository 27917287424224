import { GET_TESTIMONIALS_LIST } from './constants'

const initialState = {
  testimonialsList: null,
}

const testimonialsListReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_TESTIMONIALS_LIST:
      return {
        ...state,
        testimonialsList: payload,
      }
    default:
      return state
  }
}

export default testimonialsListReducer
