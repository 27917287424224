import { GET_CURRENT_MISSION, OVERVIEW_TRANSLATE, REQUIREMENTS_TRANSLATE, OPTIONS_TRANSLATE, PACKAGE_TRANSLATE } from './constants'

const initialState = {
  mission: null,
}

const currentMissionReducer = (state = { ...initialState }, { type, payload, lang }) => {
  switch (type) {
    case GET_CURRENT_MISSION:
      return {
        ...state,
        mission: payload,
      }
    case OVERVIEW_TRANSLATE: {
      // eslint-disable-next-line camelcase
      const { summary, volunteer_task, location_about } = payload

      return {
        ...state,
        mission: {
          ...state.mission,
          summary,
          volunteer_task,
          location_about,
        },
      }
    }
    case REQUIREMENTS_TRANSLATE: {
      const { rules } = payload

      return {
        ...state,
        mission: {
          ...state.mission,
          rules,
        },
      }
    }
    case OPTIONS_TRANSLATE: {
      const { options } = payload
      const result = options.filter((item) => item.lang === lang)
      const optionsState = state.mission.options

      const newOptions = optionsState.map((op) => {
        const currentOption = result.find((el) => el.id === op.id)
        op.description = currentOption.description
        return op
      })

      return {
        ...state,
        mission: {
          ...state.mission,
          options: newOptions,
        },
      }
    }
    case PACKAGE_TRANSLATE: {
      return {
        ...state,
        mission: {
          ...state.mission,
          package: payload.package,
        },
      }
    }
    default:
      return state
  }
}

export default currentMissionReducer
