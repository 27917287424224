import React from 'react'

/* components */
import ModalWrapper from 'components/common/Modal'
import SignIn from 'modules/auth/SignIn'
import SignUp from 'modules/auth/SignUp'

/* styles */
import classes from 'components/partials/Header/ActionsBlock/Unauthorized/index.module.scss'
import Button from '../../../../ui/Button/base'

const UnauthorizedActions = () => (
  <div className={classes.wrapper}>
    <ModalWrapper
      modalName="sign-in"

      headerTitle="Sign In"

      buttonClasses="mr-4"
      buttonSpacing="mr-4"
      buttonSize="small"
      onlySignInModal
      trigger={(
        <Button
          type="button"
          text="signInBtn"
          spacing="mr-4"
          size="small"
        />
      )}>
      <SignIn />
    </ModalWrapper>

    <ModalWrapper
      modalName="sign-up"
      headerTitle="Sign Up"
      buttonText="Sign Up"
      buttonSize="small"
      trigger={(
        <Button
          type="button"
          text="signUpBtn"
          spacing="mr-4"
          size="small"
        />
      )}>
      <SignUp />
    </ModalWrapper>
  </div>
)

export default UnauthorizedActions
