import {
  GET_CURRENT_HOST_BALANCE,
  GET_CURRENT_HOST_PROFILE,
  GET_HOST_MISSIONS,
  GET_PAYMENT_REQUESTS,
} from './constants'


const initialState = {
  profile: null,
  missions: null,
  balance: 0,
  paymentRequests: null,
}

const currentHostReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_CURRENT_HOST_PROFILE:
      return {
        ...state,
        profile: payload,
      }
    case GET_HOST_MISSIONS:
      return {
        ...state,
        missions: {
          ...state.missions,
          [payload.type]: {
            missions: payload.result,
            pagination: { ...payload.pagination.meta },
          },
        },
      }

    case GET_CURRENT_HOST_BALANCE:
      return {
        ...state,
        balance: payload,
      }

    case GET_PAYMENT_REQUESTS: {
      return {
        ...state,
        paymentRequests: payload,
      }
    }

    default:
      return state
  }
}

export default currentHostReducer
