export default {
  home: '/',
  helpCenter: '/help-center',
  createHost: '/create-host',
  structure: '/structure/:id',
  profileVolunteer: '/profile-volunteer/:id',
  profileHost: '/profile-host/:id',
  theme: '/theme/:slug',
  mission: '/mission/:slug',
  booking: '/booking/:slug',
  messenger: '/messenger/:chatId?',

  signIn: ['/sign-in', '/signin', '/login'],
  signUp: ['/sign-up', '/signup', '/register'],

  dashboard: '/dashboard',
  dashboardProfile: '/dashboard/profile/:tab?/:chatId?',
  dashboardSettings: '/dashboard/settings',

  createProfile: '/create-profile',
  createMission: '/create-mission',
  editMission: '/edit-mission/:slug',
  searchMission: '/search-mission',

  privacyPolicy: '/privacy-policy',
  conditionsPage: '/conditions',
}
