import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'

/* components */
import DynamicImage from 'components/common/DynamicImage'
import Icon from 'components/common/Icon'
import DropDown from 'components/partials/Header/ActionsBlock/Authorized/DropDown'

/* hooks */
import _useToggle from 'hooks/use-toggle'

/* styles */
import classes from 'components/partials/Header/ActionsBlock/Authorized/index.module.scss'
import routes from '../../../../../constants/routes'
import MessagesCount from '../../../../ui/MessagesCount'


const AuthorizedActions = ({
  userData,
}) => {

  const [isDropDownOpened, toggleDropDown] = _useToggle(false)

  return (
    <div className={classes.wrapper}>
      <div className={classes.messengerWrapper}>
        {userData.profile && (
          <>
            <Link to={{ pathname: formatRoute(routes.messenger) }} className={classes.messengerLink}>
              <MessagesCount />
              <Icon iconName="icon_messenger" />
            </Link>
          </>
        )}
      </div>
      <div className={classes.avatarWrapper}>
        <DynamicImage
          imageData={userData.avatar}
          size="48x48"
        />
      </div>

      <div className={classes.nameWrapper} onClick={toggleDropDown} role="button">
        <div className={classes.name}>
          {userData.firstName}
        </div>
        <div className={classes.dropdownArrow}>
          <Icon iconFolder="arrows" iconName="arrow-dropdown" />
        </div>
      </div>

      {isDropDownOpened && (
        <OutsideClickHandler onOutsideClick={toggleDropDown} disabled={!isDropDownOpened}>
          <div className={classes.dropdownWrapper}>
            <DropDown toggleParentDropDown={toggleDropDown} user={userData} />
          </div>
        </OutsideClickHandler>
      )}
    </div>
  )
}
export default AuthorizedActions
