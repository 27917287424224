import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './styles.scss'
import { renderPrivacyEn } from './renderPrivacyEn'
import { renderPrivacyFr } from './renderPrivacyFr'

const PrivacyPage = ({ lang }) => (
  <main className="main privacy-page">
    <div className="container">
      <div className="html-inner" dangerouslySetInnerHTML={{ __html: lang === 'fr' ? renderPrivacyFr : renderPrivacyEn }} />
    </div>
  </main>
)

PrivacyPage.propTypes = {
  lang: PropTypes.string,
}

PrivacyPage.defaultProps = {
  lang: 'en',
}

const mapStateToProps = (state) => ({
  lang: state.languages.currentLanguage?.code,
})
export default connect(mapStateToProps, null)(PrivacyPage)
