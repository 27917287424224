export const renderPrivacyEn = `
<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt;"><strong><span style="font-family:'Times New Roman';">Privacy Policy</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt;"><strong><span style="font-family:'Times New Roman';">Travel With A Mission&rsquo;s Volunteering platform (</span></strong><a href="https://volunteeringplatform.org" target="_blank" rel="noreferrer" style="text-decoration:none;"><strong><u><span style="font-family:'Times New Roman'; color:#0563c1;">https://volunteeringplatform.org</span></u></strong></a><strong><span style="font-family:'Times New Roman';">)</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt;"><strong><span style="font-family:'Times New Roman';">Last updated : Sept. 1</span></strong><strong><span style="font-family:'Times New Roman'; font-size:8pt;"><sup>st</sup></span></strong><strong><span style="font-family:'Times New Roman';">&nbsp;2023</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">The Travel With A Mission volunteering platform aims to promote connections between volunteers and local structures acting in the general interest. We are a community built on trust. And being transparent about how we use your information and protect your privacy is fundamental to building trust.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">This Privacy Policy describes how Travel With A Mission treats personal information relating to your use of the Travel With A Mission volunteering platform.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 1. DEFINITIONS</p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">Terms not defined in this Privacy Policy have the same definition as set forth in our Terms of Use.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify;">&nbsp;</p>
<p>&nbsp; &nbsp; &nbsp; 2. PERSONAL INFORMATION WE COLLECT</p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 2.1. Information necessary to use the Travel With A Mission platform.</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">We collect personal information about you when you use the Travel With A Mission volunteering platform. Without them, we would not be able to provide you with the services requested. This information includes:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull; Contact information, account information and profile information. Your first name, last name, telephone number, postal address, e-mail address, date of birth and profile photo, depending on the features you use.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull; Identity verification and payment information. Images of your government-issued ID (in accordance with applicable law), your ID number or other verification information, a selfie at the time of ID verification, your banking or account details of payment. If you are not a user of the Travel With A Mission Platforms, we may receive payment information about you, for example when a Travel With A Mission user provides your payment card to make a reservation. If a copy of your identification document is provided to us, we may scan, use and store the information contained in your identification document to verify your identity and for security purposes.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 2.2. Information you choose to provide to us.</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">You may choose to provide us with additional personal information. This information may include:</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Additional profile information</span></strong><span style="font-family:'Times New Roman';">. For example, gender, preferred language(s), city, and a personal description. Some of this information, as indicated in your account settings, is part of your public profile page and will therefore be publicly visible.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Information about other people</span></strong><span style="font-family:'Times New Roman';">. For example, a payment instrument belonging to another person or information about a co-volunteer. By providing us with personal information about other people, you certify that you have permission to provide such information to Travel With A Mission for the purposes described in this Privacy Policy and that you have shared the Travel With A Mission Privacy Policy. On Mission with them.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Other information</span></strong><span style="font-family:'Times New Roman';">. For example, those provided when you fill out a form, add information to your account, respond to surveys, post in community forums, participate in promotions, communicate with Travel With A Mission support and other Members, import or manually enter contacts from the address book, provide your address and/or geolocation, or share your experience with us. This may include health information, if you choose to provide it to us.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 2.3. Information collected automatically when using the Travel With A Mission platform and our payment services.</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">When you use the Travel With A Mission platform and Payment Services, we automatically collect personal information and other information. This information may include:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Geolocation information</span></strong><span style="font-family:'Times New Roman';">. A precise or approximate location determined from your IP address, the GPS of your mobile phone or other device, or other information that you share with us, depending on your device settings. We may also collect this information when you are not using the app, if you enable this option in your settings or device permissions.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Usage Information</span></strong><span style="font-family:'Times New Roman';">. The pages or content you view, assignment searches, reservations you have made, additional services you have added, and other actions taken on the Travel With A Mission platform.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Login data and device information</span></strong><span style="font-family:'Times New Roman';">. Information about how you used the Travel With A Mission platform (including whether you clicked on links to third-party applications), IP address, dates and times of access, hardware and software information, information about the device used, information about the events of the device used, unique identifiers, crash data and the pages you viewed or visited before or after using the Travel With A Mission platform. We may collect this information even if you have not created a Travel With A Mission account or logged in.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Cookies and similar technologies</span></strong><span style="font-family:'Times New Roman';">&nbsp;as described in our Cookie Policy.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Information on payment transactions</span></strong><span style="font-family:'Times New Roman';">. Payment method used, date and time, payment amount, payment method expiration date and billing postcode, PayPal email address, IBAN information, your address and others associated transaction information.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 2.5. Personal Information Collected from Third Parties.</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">We collect personal information from other sources, including:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Third-party services</span></strong><span style="font-family:'Times New Roman';">. If you link, log in or access the Travel With A Mission platform with a third party service (e.g. Facebook), you are asking that service to send us information such as your registration, friends list and profile , in accordance with the terms of control of this service or the authorization granted by you in your privacy settings relating to this service.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Information and background</span></strong><span style="font-family:'Times New Roman';">. For Members located in the United States, to the extent permitted by applicable law, we may obtain, for example, criminal records, sex offender registrations and other information about you or your background. For Local Structures in India, to the extent permitted by applicable law, we may carry out criminal background checks. For Members located outside of the United States, to the extent permitted by applicable law and with your consent where applicable, we may obtain a criminal record or registration on the sex offender registry. We may use your information, including your full name and date of birth, to obtain these documents.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Sponsorships and co-Volunteers</span></strong><span style="font-family:'Times New Roman';">. If you are invited to the Travel With A Mission platform, for example, as a co-volunteer on a trip, the person who issued the invitation may send personal information about you such as your email address or other contact information.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Travel insurance</span></strong><span style="font-family:'Times New Roman';">. If you make a request under our Travel Insurance policy, we will receive information about your request in order to process, manage or evaluate your request as set out in this Privacy Policy.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'Times New Roman';">Other sources</span></strong><span style="font-family:'Times New Roman';">. To the extent permitted by applicable law, we may receive additional information about you, such as recommendations, demographic data, or information to help detect fraud and security breaches, from third-party service providers. or partners, and cross-check them with the information about you already in our possession. For example, we may receive background check results or fraud warnings from identity verification service providers as part of our fraud prevention, security investigation, and security efforts. Risk Assessment. We may receive information about you and your activities on and off the Travel With A Mission platform, or about your experiences and interactions with our partners. We may receive health information, including, but not limited to, information regarding communicable diseases.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 3. HOW WE USE THE INFORMATION WE COLLECT</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 3,1. Provision, improvement and development of the Travel With A Mission volunteering platform. We may process this information to:</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Enable you to access the Travel With A Mission volunteering platform and make and receive payments</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Allow you to communicate with other members</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Process your request</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Perform analysis, debugging and conduct research</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Provide training to user support teams</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Send you messages, updates, security alerts and account notifications</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Process, manage or assess damage claims or similar claims</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Personalize your experience based on your interactions with the Travel With A Mission platform, your search and booking history, your profile information and preferences, and other content submitted by you</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">If you provide us with information about your contacts such as your friends or co-volunteers, we may process this information in order to: (i) facilitate your referral invitations, (ii) share your travel information and facilitate trip planning , (iii) detect and prevent fraud, and (iv) facilitate your requests or for any other purpose authorized by you.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 3.2. Create and maintain a secure environment of trust.</p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">We may process this information to:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Detect and prevent fraud, spam, abuse, security incidents and other harmful activities</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Investigate and combat discrimination in accordance with our Non-Discrimination Policy</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Conduct fraud prevention investigations, security and risk assessments</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Verify or authenticate the information you have provided</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Perform checks against databases and other sources of information, including background checks</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Respect our legal obligations, protect the health and well-being of our volunteers, local structures, local structure employees and members of the public,</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Resolve disputes with our Members, including sharing information with local structures regarding disputes related to your role as a volunteer or structure receiving volunteers,</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Enforce our agreements with third parties</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Comply with the law, respond to requests, prevent damage and protect our rights (see Article 4.5)</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Enforce our Terms and other policies (including the Non-Discrimination Policy)</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Evaluate your interactions with the Travel With A Mission platform as well as information obtained from third parties. In a limited number of cases, automated processes, which analyze your account and your activities on the Travel With A Mission platform, as well as information relating to activities on and off the Travel With A Mission platform which may be associated, may restrict or suspend your access to the Travel With A Mission platform if these processes detect activity that may present a security or other type of risk to Travel With A Mission, our community or any third party. If you wish to challenge decisions based on automated processes, please contact us using the contact details in the Contact Information section below. To learn more about how our system determines whether certain reservations may be at higher risk of incidents, visit this page.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 3.3. Analyze and share your communications.</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">We may consult, review or analyze your communications on the Travel With A Mission platform for the reasons set out in the &ldquo;Our use of the information we collect&rdquo; section of this policy, including for fraud prevention, fraud investigations, security, risk assessment, regulatory compliance, product development, research, analysis, enforcement of our Terms of Service and user support. For example, as part of our fraud prevention efforts, we crawl and analyze messages to hide contact details and references to other websites, and subject to applicable law, we review and analyze all uploaded images by users on the Travel With A Mission platform in threads, profiles, ads and experiences to detect certain illegal or inappropriate activities (such as evidence of child exploitation) with the aim of identifying and to report content violations to the appropriate authorities. In some cases, we may also review, consult or analyze messages for the purposes of fixing bugs, improving and expanding our product offerings. We use automated methods wherever possible. We may sometimes need to review communications manually, for example for fraud investigations and user support purposes, or to evaluate and improve the functionality of these automated tools. We will not access, review or analyze your communications to send you third-party marketing messages, and we will not sell reviews or analyzes of these communications. We may also share your communications as described in the &ldquo;Sharing and Disclosure&rdquo; section.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 3.4. Link to Third Party Accounts</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">You may link your Travel With A Mission account to certain third-party services such as social media. Your contacts on these third-party services are referred to as &ldquo;friends&rdquo;. When you authorize and direct data sharing by creating this link:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Some of the information provided to us from linked accounts may be posted to your public profile.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Your activities on the Travel With A Mission Platform may be displayed to your friends on the Travel With A Mission Platform or such third party service.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">A link to your public profile on such third-party service may be included in your Travel With A Mission public profile.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Other Travel With A Mission users will be able to see your possible mutual friends or that you are a friend of their friend, if applicable.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Other Travel With A Mission users can see schools, hometowns, or other groups you have in common with them as they appear on your linked social media services.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Information you disclose to us by linking your accounts may be stored, processed and transmitted for fraud prevention, security investigations and risk assessment purposes.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">The publication and display of information you provide to the Travel With A Mission Platform through these links depends on the settings and permissions set by you on the Travel With A Mission Platform and the Third Party Service.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 3.5. Providing payment services</p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">Personal information is used to enable or authorize third parties to use payment services, in particular to:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Detect and prevent money laundering, fraud, abuse and security incidents, and perform risk assessments.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Comply with legal and compliance obligations (such as anti-money laundering and sanctions enforcement regulations).</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Apply Payment Terms and other payment policies.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Provide and improve payment services.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 4. SHARING AND DISCLOSURE</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 4.1. Sharing with your consent or at your direction</p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">When you provide your consent, we share your information as described at the time of consent, such as when you authorize a third-party application or website to access your Travel With A Mission account or participate in promotional activities offered by Travel With A Mission partners. Travel With A Mission or third parties.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">Where permitted by law, we may use certain information about you, such as your email address, which we share with social media platforms, to generate potential local structures, traffic to Travel With A Mission or to promote our products and services in any other way.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; 4.2. Sharing between members</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">In order to facilitate reservations or other interactions between Members (who may be located in jurisdictions with varying levels of data protection, or use service providers located in such jurisdictions), we may share information in situations such as:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull; Between Volunteers and local structures when:</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">A booking request is made or a dispute is submitted, such as their profile, name, name of any additional Volunteers, cancellation history, comments posted, age of Volunteer (unless prohibited by law), the outcome of the dispute (if any) and other information you choose to share and send.</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">A booking is confirmed, additional information is shared to help coordinate travel, such as profile picture and phone number.</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">You confirm a booking as a local structure, certain information is shared with the Volunteer (and any additional Volunteers invited, if applicable) to coordinate the booking, such as your profile, full name, phone number and address of your accommodation.</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">You communicate with a Member, in particular by using your name, your profile photo and the content of your messages.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull; Between Volunteers when:</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">You invite as a Volunteer other Volunteers on a reservation, certain information is shared with each additional Volunteer, such as your name, dates of the trip, name of the local structure, details of the Announcement, the address of the Accommodation and other associated information.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull; Between local structures when:</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">You accept an invitation to become a Local Co-Structure, you authorize such other Member(s), such as Local Co-Structures, to access and update your information and Member Content, such as certain information such as your name listing, your phone number, your Accommodation address, your calendar, Listing information, Listing photos and your email address.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;">&nbsp; &nbsp; &nbsp; &nbsp;4.3 Information you post in Profiles, Announcements and other public information.</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">You can make certain information publicly visible to other users, such as:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Your public profile page, including your profile photo, your first name (or initials if applicable), your description and your city</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Listing pages containing information such as approximate or precise description of the accommodation or experience, availability, profile photo, aggregate information related to the request (such as the number of views of the page on a certain period) and any additional information you choose to share</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Comments, evaluations and remarks from the public</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Content in a discussion forum or community, blog or social media post</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">We may display certain portions of your public profile and other content you make publicly available, such as listing details, on third-party applications, platforms and sites.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">Information you share publicly on the Travel With A Mission platform may be indexed through third-party search engines. In some cases, you will be able to deactivate this functionality in your account settings.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;4.4 Local structure service providers</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">Local Structures may rely on third-party services to manage or provide their services, such as housekeeping or locksmith services. Local Structures may use the features of the Travel With A Mission platform to share information about the Volunteer (including arrival and departure information, name and telephone number) with such third-party service providers.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;4.5 Compliance with legislation, response to requests from police and justice authorities, prevention of harm and protection of our rights.</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">We may disclose your information to courts, law enforcement authorities, governmental or public authorities, tax authorities, authorized third parties, or other Members, if and to the extent we are required or authorized to do so by the law, or where disclosure is reasonably necessary to: (i) comply with our legal obligations; (ii) comply with a valid legal requirement (such as a court order or decision) or respond to claims against Travel With A Mission; (iii) respond to a valid legal request in connection with a criminal investigation into illegal or suspected illegal activity, or to respond to any other activity that may expose Travel With A Mission, you or any other user, to legal liability or regulatory (more information on Travel With A Mission&apos;s enforcement guidelines here), (iv) enforce and administer our contracts with Members, including our Terms, Additional Legal Terms, and Policies; or (v) protect the rights, property or personal safety of Travel With A Mission, its employees, members or members of the public.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">Where appropriate, we may notify members of legal requests, unless: (i) notification is prohibited by the legal process itself, by a court order we receive, or by applicable law ; or (ii) we believe that the notice is unnecessary, ineffective, would cause a risk of injury or bodily harm to any individual or group, or create or increase the risk of fraud or harm to Travel With A Mission or our members, or expose Travel With A Mission to an obstruction of justice claim.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">Where required or permitted by applicable law, we may disclose information relating to Local Structures or Volunteers to tax authorities or other government agencies for tax authorities to determine compliance with applicable tax obligations. Applicable tax obligations include Travel With A Mission&apos;s tax obligations on its service fees, its role as a facilitator for accommodation taxes and withholding taxes, as well as the individual tax obligations of Local Structures. Information that may be disclosed includes, but is not limited to, Local Structure and Volunteer names, accommodation addresses, Local Structure addresses, tax/business identification number(s), date of birth and/or contact details, plot identification numbers, payment information, dates and amounts of transactions, number of nights booked as well as number of Volunteers, gross and net value of bookings and amounts of payments, taxes collected by Travel With A Mission on behalf of Volunteers and local Structures, insofar as this information is known to Travel With A Mission.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">In jurisdictions where Travel With A Mission encourages or requires the process of registration, notification, authorization, obtaining a license or the number of a Local Structure with a governmental authority, we may communicate information on the Participating Local Structures with the competent authority, both during the application process and when publishing the announcement, and if necessary, periodically thereafter, such as the full name and contact details of the structure location, listing address, tax identification number, registration, permit or license number, listing details, booking information and number of nights booked, subject to applicable laws .</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;4.6 Service providers</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">We share personal information with affiliated and unaffiliated service providers (including their service providers) to help us administer our business and for the purposes of their own compliance requirements, including with those that help us: (i) verify your identity or to authenticate your identity documents, (ii) to compare information with public databases, (iii) to carry out criminal background checks, fraud prevention, security investigations and risk assessments , (iv) to conduct product development, maintenance and debugging, (v) to enable the provision of the Travel With A Mission Services through third-party software platforms and tools (for example, by integrating with our API), (vi) to provide user support, advertising or payment services, (vii) to offer additional services that you choose, (viii) to process, manage or assess damage claims or claims similar, or (ix) to view, review and analyze communications on the Travel With A Mission Platform for certain purposes (such as evidence of child exploitation). For more information, see Analyze and share your communications. These service providers are contractually bound to protect your personal information and only have access to it to perform the tasks mentioned above. Other Members may use services other than Travel With A Mission to process your data. This could be email or reservation management software. These services are beyond the control of Travel With A Mission and will be subject to applicable laws around the world with varying levels of data protection.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;5. OTHER IMPORTANT INFORMATION</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;5.1 Third-Party Partners and Integrations.</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">Certain parts of Travel With A Mission may link to third party services. Travel With A Mission does not own or control these third parties. When you interact with these third parties and choose to use their service, you are providing them with information about yourself. Your use of these services is subject to the privacy policies of those providers, including the Google Maps/Google Earth Additional Terms of Service, the Google Privacy Policy (see here for more information on how Google uses information), the PayPal Privacy Statement, the Citi Privacy Policy and the Stripe Privacy Policy.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;6. YOUR RIGHTS</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">You may exercise any of the rights described in this section in accordance with applicable law. See here for information on data subject rights requests and how to submit a request. We may ask you to verify your identity before acting on your request.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">To find out more about your rights under the General Data Protection Regulation (&ldquo;GDPR&rdquo;) and the general data protection law in France, see online.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;6.1 Managing your information</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">You can view and update some of your information by accessing your account settings. If you have connected your Travel With A Mission account to a third-party service, such as Facebook or Google, you can change your settings and disconnect from that service by going to your account settings. You are responsible for updating your personal information.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;6.2 Data access and portability</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">In some jurisdictions, applicable law may give you the right to request copies of your personal information or information about how we process your personal information, or to request copies of the personal information you have provided to us in a structured, commonly used and machine-readable format, and/or to request that we transmit this information to another service provider (where technically feasible).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;6.3 Deletion of data</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">In some jurisdictions, you can request deletion of your personal information. Please note that if you request deletion of your personal information, or if your account is suspended, terminated or voluntarily closed:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">We may retain personal information as necessary for our legitimate interests, such as detecting and preventing fraud and improving security. For example, if we suspend a Travel With A Mission account for fraud or security reasons, we may retain certain information related to that Travel With A Mission account to prevent that member from opening a new Travel With A Mission account. &apos;future.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">We may retain and use your personal information as necessary to comply with our legal obligations. For example, Travel With A Mission may retain some of your information for tax, legal reporting and auditing purposes.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Information that you have shared with others (eg comments, forum posts) may continue to be public on the Travel With A Mission platform also after your Travel With A Mission account is deleted.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&bull;</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'Times New Roman';">Because we protect information from accidental or malicious loss and destruction, residual copies of your personal information may not be deleted from our backup systems for a limited period of time.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;7. SECURITY</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">Although no organization can guarantee perfect security, we continuously implement and update our administrative, technical and physical security measures to protect your information against unlawful or unauthorized access, loss, destruction or modification.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;8. CHANGES TO THIS PRIVACY POLICY</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">We reserve the right to modify this Privacy Policy at any time in accordance with applicable law. If applicable, we will post the revised version of the Privacy Policy and update the &ldquo;Last Updated Date&rdquo; at the beginning of it. In the event of a significant modification, we will also inform you by e-mail at least thirty (30) days before the date of entry into force of the new version. If you do not agree to the new terms of the Privacy Policy, we invite you to delete your account. If you do not delete your account before the effective date of the new Privacy Policy, your continued access and use of the Travel With A Mission platform will be subject to the new Privacy Policy.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp;9. CONTACT DETAILS AND RESPONSIBLE TRAVEL WITH A MISSION ENTITIES</p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">If you have any questions or complaints regarding this Privacy Policy or Travel With A Mission&apos;s processing of personal information, please email us at <a href="mailto:info@travelwithamission.org">info@travelwithamission.org</a>.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; line-height:normal; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; line-height:108%; font-size:12pt;"><span style="font-family:'Times New Roman';">&nbsp;</span></p>`
