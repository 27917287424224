import { volunteeringRequestHandler } from '../../api/http-client/request-handler'


const MessagingService = {
  getConversationList(page, search) {
    return volunteeringRequestHandler.get('users/me/conversations', {
      params: {
        platform: 'vol',
        page,
        search,
      },
    })
  },

  createChatWithUser(userId) {
    return volunteeringRequestHandler.post('users/me/conversations', { user_id: userId }, {
      params: {
        platform: 'vol',
      },
    })
  },

  getChatRoom(conversationId) {
    return volunteeringRequestHandler.get(`users/me/conversations/${conversationId}`, {
      params: {
        platform: 'vol',
      },
    })
  },

  getMessages(conversationId, messagesPage) {
    return volunteeringRequestHandler.get(`users/me/conversations/${conversationId}/messages`, {
      params: {
        page: messagesPage || 1,
        platform: 'vol',
      },
    })
  },

  sendMessage(conversationId, message) {
    return volunteeringRequestHandler.post(`users/me/conversations/${conversationId}/messages`, message, {
      params: {
        platform: 'vol',
      },
    })
  },
}

export default MessagingService
