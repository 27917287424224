import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { formatRoute } from 'react-router-named-routes'
import { useTranslation } from 'react-i18next'

/* constants */
import routes from 'constants/routes'

/* components */
import Icon from 'components/common/Icon'

/* hooks */
import _useToggle from 'hooks/use-toggle'

/* actions */
import { signOutAction } from 'modules/auth/store/actions'

/* styles */
import classes from 'components/partials/Header/ActionsBlock/Authorized/DropDown/index.module.scss'
import RequestsCount from '../../../../../../dashboard/Profile/HostProfile/common/RequestsCount'

const UserDropDown = ({ toggleParentDropDown, signOut, user }) => {
  const { t } = useTranslation('header_volunteering')

  const items = [
    {
      name: 'profile',
      text: t('userDDMyProfile'),
      path: formatRoute(user?.profile?.role === 'Volunteer' ? routes.profileVolunteer : routes.profileHost, { id: user?.userId }),
    },
    {
      name: 'account',
      text: t('userDDAccount'),
      nestedItems: user?.profile?.role === 'Volunteer'
        ? [
          {
            name: 'personal-info',
            text: t('userDDPersonalInfo'),
            path: formatRoute(routes.dashboardProfile, { tab: 'personal-info' }),
          },
          {
            name: 'volunteers',
            text: <RequestsCount
              title="userDDActivities"
            />,
            path: formatRoute(routes.dashboardProfile, { tab: 'volunteers' }),
          },
          {
            name: 'missions',
            text: t('userDDFavorites'),
            path: formatRoute(routes.dashboardProfile, { tab: 'missions' }),
          },
          {
            name: 'revenues',
            text: t('userDDPayments'),
            path: formatRoute(routes.dashboardProfile, { tab: 'revenues' }),
          },
          {
            name: 'refunds',
            text: t('userDDRefunds'),
            path: formatRoute(routes.dashboardProfile, { tab: 'refunds' }),
          },
        ]
        : [
          {
            name: 'personal-info',
            text: t('userDDPersonalInfo'),
            path: formatRoute(routes.dashboardProfile, { tab: 'personal-info' }),
          },
          {
            name: 'structure',
            text: t('userDDStructure'),
            path: formatRoute(routes.dashboardProfile, { tab: 'structure' }),
          },
          {
            name: 'volunteers',
            text: <RequestsCount
              title="userDDVolunteers"
            />,
            path: formatRoute(routes.dashboardProfile, { tab: 'volunteers' }),
          },
          {
            name: 'missions',
            text: t('userDDMissions'),
            path: formatRoute(routes.dashboardProfile, { tab: 'missions' }),
          },
          {
            name: 'revenues',
            text: t('userDDRevenues'),
            path: formatRoute(routes.dashboardProfile, { tab: 'revenues' }),
          },
          {
            name: 'refunds',
            text: t('userDDRefunds'),
            path: formatRoute(routes.dashboardProfile, { tab: 'refunds' }),
          },
          {
            name: 'balance',
            text: 'Balance',
            path: formatRoute(routes.dashboardProfile, { tab: 'balance' }),
          },
        ],
    },
    {
      name: 'settings',
      text: t('userDDSettings'),
      path: routes.dashboardSettings,
    },
    {
      name: 'help-center',
      text: t('userDDHelpCenter'),
      path: 'help-center',
    },
    {
      name: 'sign-out',
      text: t('userDDSignOut'),
      action: signOut,
    },
  ]

  const [isNestedOpened, toggleNested] = _useToggle(false)

  const renderDropDownItemInner = (item) => (
    <div className={classes.itemInnerWrapper}>
      <div className={classes.icon}>
        <Icon iconFolder="user-dropdown" iconName={item.name} />
      </div>
      <div className={classes.text}>
        {item.text}
      </div>
    </div>
  )

  const renderDropDownItems = (items) => items.map((item, index) => (
    <li key={index} className={classes.itemWrapper}>
      {item.path && (
        <Link to={formatRoute(routes.home + item.path)} className={classes.itemInner} onClick={toggleParentDropDown}>
          {renderDropDownItemInner(item)}
        </Link>
      )}

      {item.action && (
        <div
          onClick={() => {
            toggleParentDropDown()
            item.action()
          }}
          className={classes.itemInner}
          role="button">
          {renderDropDownItemInner(item)}
        </div>
      )}

      {item.nestedItems && item.nestedItems.length && (
        <>
          <div className={clsx(classes.itemInner, classes.itemInnerNested)} onClick={toggleNested} role="button">
            {renderDropDownItemInner(item)}
            <Icon iconFolder="arrows" iconName="arrow-dropdown" />
          </div>
          {isNestedOpened && (
            <ul className={clsx(classes.dropdown, classes.dropdownNested)}>
              {renderDropDownItems(item.nestedItems)}
            </ul>
          )}
        </>
      )}
    </li>
  ))

  return (
    <ul className={classes.dropDown}>
      {renderDropDownItems(items)}
    </ul>
  )
}

const actionsToProps = {
  signOut: signOutAction,
}

export default connect(null, actionsToProps)(UserDropDown)
