import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import _useDidMount from '../../../hooks/lifecycle/use-did-mount'

/* eslint-disable global-require,import/no-dynamic-require */
const Icon = ({
  iconName,
  iconFolder = '',
  extension = 'svg',
  size,
  className,
  onClick,
}) => {
  const [image, setImage] = useState('')

  _useDidMount(() => {
    try {
      const image = require(`assets/icons/${iconFolder}${iconName}.png`)
      setImage(image)
    } catch (error) {
      setImage(`assets/icons/${iconFolder}ae.png`)
    }
  })
  if (!iconName) {
    return null
  }

  if (iconFolder) {
    iconFolder += '/'
  }

  if (!image) return null

  if (extension === 'png') {
    const [width, height] = size.split('x')

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <img
        src={image}
        width={width}
        height={height}
        className={className}
        alt="icon"
        onClick={onClick}
      />
    )
  }

  return (
    <SVG
      src={require(`assets/icons/${iconFolder}${iconName}.svg`)}
      className={className}
      onClick={onClick}
    />
  )
}

export default Icon
