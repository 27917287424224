import { MAIN_URL } from 'constants/main'

export const menuItems = [
  {
    id: 0,
    label: 'volunteering',
    path: '/',
  },
  {
    id: 1,
    label: 'solidarityTrips',
    path: 'https://twaming.org/',
  },
  {
    id: 2,
    label: 'projects',
    path: 'https://voyagessolidaires.travelwithamission.org/',
  },
  {
    id: 3,
    label: 'events',
    path: 'https://evenements.travelwithamission.org/',
  },
  {
    id: 4,
    label: 'sensitization',
    path: 'https://projets.travelwithamission.org/',
  },
  {
    id: 5,
    label: 'support',
    path: 'https://soutien.travelwithamission.org/',
  },
  {
    id: 6,
    label: 'blog',
    path: 'https://blog.travelwithamission.org/',
  },
  {
    id: 7,
    label: 'footer-privacy',
    path: `${MAIN_URL}/privacy-policy`,
  },
  {
    id: 8,
    label: 'footer-conditions',
    path: `${MAIN_URL}/conditions`,
  },
]
