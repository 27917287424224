import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { history } from 'store'

/* components */
import Input from 'components/ui/Inputs/Base'
import PasswordInput from 'components/ui/Inputs/Password'
import Button from 'components/ui/Button/base'
import SectionTitle from 'components/ui/SectionTitle'
import FacebookAuth from 'modules/auth/FacebookAuth'
import Icon from 'components/common/Icon'

/* utils */
import customEvent from 'utils/custom-event'
import { ForgotPasswordContext } from '../../../utils/ForgotPasswordContext'

/* constants */
import { modalVisibilityActions } from 'components/common/Modal/constants'
import routes from 'constants/routes'
import { formFields } from './constants'

/* actions */
import { signInAction, facebookAuthAction } from '../store/actions'
import AuthService from '../AuthService'

/* styles */
import classes from './index.module.scss'

const SignInForm = ({ signIn, facebookAuth, hash, query }) => {
  const [isChangePassword, setIsChangePassword] = useState(hash)
  const [canResetPassword, setCanResetPassword] = useState(true)
  const { isForgotPassword, setIsForgotPassword } = useContext(ForgotPasswordContext)

  const closeThisModal = () => customEvent.trigger(`${modalVisibilityActions.close}sign-in`)

  const formikForgotPassword = useFormik({
    initialValues: {
      [formFields.email]: '',
    },

    validationSchema: Yup.object({
      email: Yup.string().email().required(),
    }),

    onSubmit: async (values, { resetForm }) => {
      await AuthService.forgotPassword(values)
        .then(() => {
          toast.success('The password recovery email has been sent!')
        })
        .catch(() => {
          toast.error('Forgot password error')
        })

      resetForm()
      closeThisModal()
      setIsForgotPassword(false)
    },
  })

  useEffect(() => {
    const checkCanResetPassword = async (email) => (
      AuthService.canResetPassword({ email })
        .then((data) => {
          setCanResetPassword(data.canReset)
        })
        .catch(() => {
          toast.error('Reset password error')
          setCanResetPassword(false)
        })
    )
    query.email && checkCanResetPassword(query.email)
  }, [query.email])

  const socialLogin = (res) => {
    facebookAuth(res)
    closeThisModal()
  }

  const formikSignIn = useFormik({
    initialValues: {
      [formFields.email]: '',
      [formFields.password]: '',
    },

    validationSchema: Yup.object({
      [formFields.email]: Yup.string().email().required(),
      [formFields.password]: Yup.string().min(8).required(),
    }),

    onSubmit: (values, { resetForm }) => {
      signIn(values)

      resetForm()

      closeThisModal()
    },
  })

  const _renderSignInForm = (formik) => {
    const hasError = (fieldName) => formik.touched[fieldName] && formik.errors[fieldName]
    const emailHasError = hasError(formFields.email)
    const passwordHasError = hasError(formFields.password)

    return (
      <form onSubmit={formik.handleSubmit}>
        <SectionTitle item="signInFormTitle" />

        <FacebookAuth
          type="signin"
          callback={socialLogin}
        />

        <Input
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.email}
          name={formFields.email}
          label={formFields.email}
          placeholder="formEmailPlaceholder"
          type={formFields.email}

          error={emailHasError ? formik.errors.email : null}
          errorInput={emailHasError ? 'errorInput' : null}
          errorLabel={emailHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <PasswordInput
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.password}
          name={formFields.password}
          label={formFields.password}
          placeholder="formPasswordPlaceholder"

          error={passwordHasError ? formik.errors.password : null}
          errorInput={passwordHasError ? 'errorInput' : null}
          errorLabel={passwordHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <Button
          text="signInBtn"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || !formik.values.email || !formik.values.password}

          type="submit"
          size="large"
          color="btn-primary"
          spacing="mb-6 mt-6"
        />

        <Button
          text="forgotPasswordBtn"
          /* eslint-disable-next-line no-use-before-define */
          onClick={() => setIsForgotPassword(true)}

          type="button"
          size="large"
          color="primaryNotBg"
        />

      </form>
    )
  }

  const _renderForgotPasswordForm = (formik) => {
    const hasError = (fieldName) => formik.touched[fieldName] && formik.errors[fieldName]
    const emailHasError = hasError(formFields.email)

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.goBackButton} onClick={() => setIsForgotPassword(false)} role="button">
          <Icon iconFolder="arrows" iconName="prev" />
        </div>
        <SectionTitle item="newForgotPasswordTitle" />

        <Input
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.email}
          name={formFields.email}
          label={formFields.email}
          placeholder="formEmailPlaceholder"
          type={formFields.email}

          error={emailHasError ? formik.errors.email : null}
          errorInput={emailHasError ? 'errorInput' : null}
          errorLabel={emailHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <Button
          text="recoverPasswordBtn"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || !formik.values.email}

          type="button"
          size="large"
          color="primary"
          spacing="mb-11 mt-6"
        />

      </form>
    )
  }

  const formikChangePassword = useFormik({
    initialValues: {
      [formFields.password]: '',
      [formFields.confirmPassword]: '',
    },
    validateOnMount: true,

    validationSchema: Yup.object({
      password: Yup.string().min(8).required(),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required(),
    }),

    onSubmit: async (values, { resetForm }) => {
      const formData = { email: query.email, password: values.password, password_confirmation: values.confirmPassword, token: query.token }
      await AuthService.resetPassword(formData)
        .then(() => {
          toast.success('The password has been updated!')
        })
        .catch(() => {
          toast.error('Reset password error')
        })
      resetForm()
      history.push(routes.home)
      setIsChangePassword(false)
      closeThisModal()
    },
  })

  const _renderChangePasswordForm = (formik) => {
    const hasError = (fieldName) => formik.touched[fieldName] && formik.errors[fieldName]
    const passwordHasError = hasError(formFields.password)
    const confirmPasswordHasError = hasError(formFields.confirmPassword)

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.goBackButton} onClick={() => setIsChangePassword(false)} role="button">
          <Icon iconFolder="arrows" iconName="prev" />
        </div>
        <SectionTitle item="newForgotPasswordTitle" />

        <PasswordInput
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.password}
          name={formFields.password}
          label={formFields.password}
          placeholder="formPasswordPlaceholder"

          error={passwordHasError ? formik.errors.password : null}
          errorInput={passwordHasError ? 'errorInput' : null}
          errorLabel={passwordHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />
        <PasswordInput
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.confirmPassword}
          name={formFields.confirmPassword}
          label={formFields.confirmPassword}
          placeholder="formPasswordPlaceholder"

          error={confirmPasswordHasError ? formik.errors.confirmPassword : null}
          errorInput={confirmPasswordHasError ? 'errorInput' : null}
          errorLabel={confirmPasswordHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <Button
          text="recoverPasswordBtn"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || (passwordHasError && confirmPasswordHasError)}

          type="button"
          size="large"
          color="primary"
          spacing="mb-11 mt-6"
        />

      </form>
    )
  }

  if (isChangePassword && canResetPassword) return _renderChangePasswordForm(formikChangePassword)
  if (isForgotPassword) return _renderForgotPasswordForm(formikForgotPassword)
  return _renderSignInForm(formikSignIn)
}

SignInForm.propTypes = {
  signIn: PropTypes.func.isRequired,
  facebookAuth: PropTypes.func.isRequired,
  hash: PropTypes.string.isRequired,
}

SignInForm.defaultProps = {}

const mapStateToProps = (state) => ({
  hash: state.router.location.hash,
  query: state.router.location.query,
})

export default connect(
  mapStateToProps,
  {
    signIn: signInAction,
    facebookAuth: facebookAuthAction,
  },
)(SignInForm)
