export const renderConditionsEn = `<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">GENERAL CONDITIONS OF USE OF THE VOLUNTEERING PLATFORM</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Terms and conditions</span></strong></p>
<p><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 1: Purpose</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">These General Conditions of Use legally govern access and use of the site https://volunteeringplatform.org/ (hereinafter referred to as &ldquo;the Volunteering Platform&rdquo; or &ldquo;Platform&rdquo;), mobile application or services ( hereinafter referred to as &ldquo;the Services&rdquo;) provided by Travel With A Mission (hereinafter referred to as TWAM).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Constituting the contract between the Volunteering Platform and the User, access to the site must be based on acceptance of these General Conditions of Use.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">By clicking &quot;I Agree&quot; or registering to use our Services, you agree to be bound by these terms.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><br></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 2: Content</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteering Platform offers an online tool allowing users (hereinafter referred to as &ldquo;Members&rdquo;) to publish, offer, search and reserve Services.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Members who post and offer Services are &ldquo;Hosts&rdquo; and Members who offer or use Services are &ldquo;Volunteers&rdquo;.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Hosts offer Solidarity Missions (hereinafter referred to as &ldquo;Mission&rdquo; or &ldquo;Solidarity Mission&rdquo;) with the possibility of assimilating a set of services (hereinafter referred to as &ldquo;Package&rdquo;).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Solidarity Missions can be diverse and offered to Travelers free of charge or for a fee.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Package offered by the Host is a set of services that are included in the Mission. It can consist of accommodation, food, local transport, various activities and training. In addition, it is possible to offer options for an additional cost to be borne by the Volunteer.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Volunteers reserve Solidarity Missions with the possibility of choosing the dates of the stay in agreement with the Hosts.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Volunteers must reserve their Mission within a certain period of time before leaving, the latter being indicated by the Hosts in the announcement.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">In addition to these general conditions, TWAM also applies additional Conditions and Policies; the Privacy Policy, which describes the collection and use of personal data of the Volunteering Platform, and the payment terms which govern all payment services provided to Members on the Volunteering Platform.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 3: Legal notices</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteering Platform is published by the Travel With A Mission association, registered in Nice under number W062006565, SIRET 79071733400029, whose head office is located at 945 Boulevard du Mar&eacute;chal Leclerc, 06360 &Egrave;ze, France.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">TWAM is a non-profit association established in accordance with the French law of July 1, 1901 relating to the association contract.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Although the information published on the Platform is deemed reliable, TWAM is not responsible for the information published by Hosts regarding the solidarity missions offered.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The latter remain responsible for the precise, complete and exact nature of the information which concerns them, as well as that which is published on the Volunteering Platform.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">TWAM does not offer any financial assistance to pay for the Solidarity Missions.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">As part of the volunteering platform, TWAM plays a facilitator role, connecting supply and demand (cf. a volunteer and a host structure).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Package that the Host offers is however contractual, as soon as the latter accepts a Mission from a volunteer.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">These General Conditions of Use form an integral part of the volunteering contract signed between the Volunteer and the Host. They should be read in conjunction with the registration form.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Hosts must understand and comply with all laws, regulations, regulations and third party agreements that apply to the Services.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 4: Access to the site</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteering Platform provides free access to the following Services:</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Publication of Solidarity Missions;</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Proposal of Solidarity Missions;</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Consultation of Solidarity Missions;</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Search for Solidarity Missions;</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Reservation of Solidarity Missions.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteering Platform is accessible free of charge from anywhere, by any user with internet access.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">All costs necessary to access these Services are the responsibility of the user. This includes computer equipment, internet connection, etc.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">To be a member, it is necessary to:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Create an account, it will allow access and use of many features that the Volunteering Platform offers.</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Keep your account information accurate. Access to Services dedicated to Members is via a username and password.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Members agree to create only one personal account and not to create an account for others without authorization.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">If Members use the Services on behalf of an entity, they warrant that they are duly authorized to accept these terms on behalf of that entity.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">This entity will be responsible for the possible violation of these conditions.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Due to maintenance or other upkeep of the Platform, access to it may be interrupted or suspended by TWAM without notice or justification.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Volunteer Terms</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 5: Responsibilities of the volunteer on the use of the Platform</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer guarantees to be at least 18 years old at the time of registration.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer undertakes to provide accurate personal information. The latter is responsible for updating the data concerning him or the entity he represents and those concerning the Solidarity Missions he offers.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer Platform offers the possibility to post comments, posts, share videos and photos. All this content is public and can be accessed by other users, even non-Members.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer assumes full responsibility for all activities that occur under their account as a Member. Thus, he accepts the risks of any authorized or unauthorized access.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 6: Responsibility of the volunteer during Solidarity Missions</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">6.1. Eligibility to go on a Solidarity Mission</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer must have correctly completed the online registration form, indicating all the mandatory information deemed necessary in order to best prepare for the mission in the foreign country.</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer guarantees the accuracy of the information provided to the Host.</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer must not have any medical contraindication.</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer must not have been convicted of a crime or sentenced to at least two months&apos; imprisonment without suspension for an offense listed in article L133-6 of the Code of Social Action and Families French.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Unless required by the Host, TWAM does not set a minimum age or a maximum age.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Anyone can go on a Solidarity Mission if they meet the prerequisites established by the Host.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">With the agreement of the Host, children, accompanied by their parents, as well as retired people can carry out a Solidarity Mission.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Unless required by the Host, TWAM places no restrictions on the nationality of the Volunteer.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">However, it is reminded that all Volunteers must be in order with their visas and vaccines to be able to enter the country legally.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Unless required by the Host, the volunteer does not need to have any special skills to carry out a Solidarity Mission. No language level is required.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">When booking a Solidarity Mission, the Volunteer&apos;s identity papers and all necessary documents must be provided to TWAM and the Host.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&bull;</span><span style="width:12.9pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">6.2. Responsibility of the volunteer before the solidarity mission</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer must have correctly completed the online registration form, indicating all the mandatory information concerning him.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Once the reservation is finalized, it is the responsibility of the Volunteer to provide the Host with the details of his arrival conditions (train ticket, plane ticket, etc.) so that the Host can honor his part of the contract (reception, etc.).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer is asked to find out about and carry out the mandatory vaccine(s) with their attending physician or with the vaccination center near their home for foreign countries.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer is asked to respect the conditions of entry into the country (possible visa, etc.).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:35.4pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">6.3. Responsibility of the volunteer during the solidarity mission</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Once the reservation and payment of the Solidarity Mission have been made, the commitments between the Volunteer and the Host become contractual.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer must respect all rules previously established by the Host.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer must have exemplary behavior at the location of the Solidarity Mission throughout the stay.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer also undertakes to respect the laws of the country of the Solidarity Mission. If the host country is subject to particular traditions or cultures, the Volunteer is responsible for adapting to them.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Host Terms</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 7: Responsibility of the Host during Solidarity Missions</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">7.1. Responsibility of the Host before the Solidarity Mission</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Host must have correctly completed the online registration form, indicating all the mandatory information deemed necessary in order to best prepare for the Volunteer&apos;s arrival at the location of the Solidarity Mission.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Host must provide TWAM with the documents recognizing the structure.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Host guarantees the accuracy of the information provided to the volunteer.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Host has the possibility on the platform to put in place restrictions for people who would like to carry out the Missions it offers. These restrictions may relate to age, nationality, skills, language, physical condition, etc.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Host decides the content of his package and the associated price that the volunteer will have to pay before the start of the mission.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Solidarity Mission can also be free if the Host decides so. The contractual package, however, remains valid, despite the fact that it is free.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Host reserves the right to refuse a Volunteer if the latter does not correspond to the desired profile.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">After booking a Solidarity Mission by the Volunteer, the Host has 48 hours to respond positively or negatively.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Once the mission is accepted, the Host must respect the package becoming a contract. In the event of non-compliance, he or she would be exposed to sanctions (reimbursement of the volunteer&apos;s plane ticket, etc.) in the event that the Volunteer were to &ldquo;raise a dispute&rdquo; by appealing to TWAM mediation.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Host guarantees the proper care of the volunteer in the context of carrying out the Solidarity Mission.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:35.4pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">7.2. Responsibility of the Host during the Solidarity Mission</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Once the reservation and payment for the Mission are made, the commitments between the Volunteer and the Host become contractual.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Host is therefore obliged to carry out all of the elements proposed in the Package. Otherwise, the volunteer may &ldquo;raise a dispute&rdquo; through TWAM mediation in order to attempt to obtain compensation.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 8: Modification, cancellation, termination and breach of contract</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Once the reservation and payment for the Mission are made, the commitments between the Volunteer and the Host become contractual.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">However :</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">- The volunteer has the possibility to terminate the contract with the Host at any time in writing (mail or email).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">- The Host has the possibility to terminate the contract with the Volunteer at any time in writing (mail or email).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">This for their own reasons.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">If this happens, 2 solutions may present themselves:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">1) The two parties agree on a modification of the program and/or a possible reimbursement of part or all of the amount. They inform TWAM by writing to info@travelwithamission.org,</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">2) The two parties do not agree on a modification of the program and/or a possible reimbursement of part or all of the amount. They can &ldquo;raise a dispute&rdquo; by each presenting their arguments to TWAM who will play the role of mediator and decide what action to take.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 9: Release of TWAM&apos;s liability</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">As part of the volunteering platform, TWAM positions itself as a facilitator and not an organizer (see https://volontariat.travelwithamission.org for supervised volunteering and the &ldquo;organizer&rdquo; positioning).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">However, in order to promote security, TWAM asks local structures for the official papers of their structure, the identity papers of Members and plays the role of mediator when there is a dispute.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">TWAM cannot however be held responsible:</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">- The content of profiles, missions and packages offered by Members,</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">- Various and varied circumstances that would lead to modifications or cancellations of the stay (eg illness, change of mind, strike, disturbance of the public peace, fire, flood, weather condition, civil revolt, war, act of terrorism, military operation , invasion, rebellion, pandemic, volcanic eruption, disease, robbery, theft, boycott, arrest, technical malfunction, power cuts, breakdown of vehicles, etc.),</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">- The various climatic phenomena of which the Volunteer could be victim during his mission, including floods, fog, fires, etc.,</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">- Damage caused to the volunteer as a result of falls, accidents, illnesses, hospitalization, medical expenses, damage to luggage, failure to perform certain actions or poor performance, inconvenience or any direct or indirect damage that may be caused to the Volunteer during the stay.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">TWAM will therefore be exonerated from any responsibility for a cancellation / modification of a mission and will not bear any costs related to any changes or accidents.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The Volunteer is himself responsible for taking out all-risk travel insurance that can reimburse the cancellation of all the costs incurred for the Mission and to cover damage caused to the volunteer as a result of falls, accidents, illnesses, hospitalization, expenses medical conditions, damage to luggage, failure to perform certain actions or poor performance, inconvenience or any direct or indirect damage that may be caused to the Volunteer during the stay.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Through partnerships, TWAM offers discounts on these insurances (https://soutien.travelwithamission.org/reductions/).</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 10: Jurisdiction</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The parties agree that these terms and conditions will be governed and interpreted in accordance with French law.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">In the event of any dispute in relation to these Conditions or the Volunteer Contract, the parties attribute exclusive jurisdiction to the courts of the city of Nice (France).</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 11: Image rights</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">In order to highlight the positive impact of Volunteers on local communities, TWAM highlights photos of Missions carried out by its Volunteers. If the Volunteer does not want photos to be distributed, simply make a simple request in writing.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">If the Host wishes to use the photos taken during the stay to highlight their activities and the Volunteer wishes to respond to their image rights, the latter must notify the Host.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 12: Data collection</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">For the creation of the member account, the collection of information at the time of registration on the site is necessary and mandatory. In accordance with French law n &deg; 78-17 of January 6, 1978 as amended relating to data processing, files and freedoms, the collection and processing of personal information is carried out with respect for privacy. Pursuant to articles 39 and following of the said law, users have the right to access and rectify information concerning them.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><strong><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">Article 13: Intellectual protection</span></strong></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">The trademarks, logos and content of the Volunteering Platform; including illustrations, graphics and text are the exclusive property of TWAM and its service providers. The property of TWAM is protected by the Intellectual Property Code and by copyright. By the protection of the Code of intellectual property and copyright, the reproduction and copying of content by users requires prior authorization from the site. Therefore, any use for commercial purposes or for advertising purposes is prohibited. Any unlawful use or action referred to above will constitute a material breach of TWAM&apos;s intellectual property rights.</span></p>
<p style="margin-top:0pt; margin-bottom:8pt; text-align:justify; line-height:108%; font-size:11.5pt;"><span style="font-family:'AAAAAC+TimesNewRomanPSMT';">&nbsp;</span></p>`
