export const isEqual = (oldObj, newObj) => JSON.stringify(oldObj) === JSON.stringify(newObj)

export const objToFormData = ({ data = {} }) => Object.entries(data)
  .reduce((formData, [key, value]) => {
    if (Array.isArray(value) && value.length) {
      value.forEach((val) => formData.append(key, val))
    } else {
      formData.append(key, value)
    }
    return formData
  }, new FormData())
