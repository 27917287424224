import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './SpriteIcon.module.scss'

const SpriteIcon = ({ name, className, size, colorIcon, ...props }) => (
  <svg
    className={clsx(classes[size], className)}
    {...props}>
    <use xlinkHref={`#${name}`} fill={colorIcon} />
  </svg>
)


SpriteIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'w-20px', 'close', 'linkedin', 'twitter', 'facebook', 'instagram', 'custom']),
  colorIcon: PropTypes.string,
  rotate: PropTypes.number,
}

SpriteIcon.defaultProps = {
  colorIcon: '',
  size: 'sm',
  rotate: 0,
}

export default SpriteIcon
