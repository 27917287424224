import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'


/* components */
import Button from 'components/ui/Button/base'
import Icon from 'components/common/Icon'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'

/* constants */
import { modalVisibilityActions } from 'components/common/Modal/constants'

/* styles */
import classes from 'components/common/Modal/index.module.scss'

/* actions */

import clsx from 'clsx'
import { closeSignInModalAction } from '../../../modules/app/store/actions'

const ModalWrapper = ({
  modalName,
  size,
  children,
  classNameWrapper,
  additionalOnOpenFunction,
  trigger,
  isSignInModalOpen,
  closeSignInModal,
  onlySignInModal,
  headerClassName,

  closeModal,
}) => {

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)

    if (additionalOnOpenFunction && typeof additionalOnOpenFunction === 'function') {
      additionalOnOpenFunction()
    }
  }

  useEffect(() => {
    if (closeModal) {
      setOpen(false)
    }
    if (isSignInModalOpen && onlySignInModal) {
      setOpen(true)
    }
  }, [isSignInModalOpen, closeModal, onlySignInModal])

  const handleClose = () => {
    setOpen(false)
    closeSignInModal()
  }

  _useCustomEventListener(`${modalVisibilityActions.open}${modalName}`, handleOpen)
  _useCustomEventListener(`${modalVisibilityActions.close}${modalName}`, handleClose)

  return (
    <>
      {React.cloneElement(
        trigger, {
          onClick: handleOpen,
        },
      )}
      <Modal
        size={size}
        dialogClassName={clsx({ [classNameWrapper]: classNameWrapper }, { [classes.modalDialog]: !classNameWrapper })}
        show={open}
        onHide={handleClose}
        centered>
        <div className={clsx({ [classes.modalHeader]: !headerClassName }, { [headerClassName]: headerClassName })}>
          <div className={classes.modalCloseButton} onClick={handleClose} role="button">
            <Icon iconName="close" />
          </div>
        </div>
        <div className={classes.modalBody}>
          {children}
        </div>
      </Modal>
    </>
  )
}


ModalWrapper.propTypes = {
  modalName: PropTypes.string.isRequired,
  trigger: PropTypes.node,
  children: PropTypes.any,
  additionalOnOpenFunction: PropTypes.func,
  closeSignInModal: PropTypes.func,
  isSignInModalOpen: PropTypes.bool,
  onlySignInModal: PropTypes.bool,
  classNameWrapper: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm', 'xl']),
}

ModalWrapper.defaultProps = {
  trigger: <Button
    type="button"
    text="signInBtn"
    spacing="mr-4"
    size="small"
  />,
}

export default connect(({ app: { isSignInModalOpen } }) => ({ isSignInModalOpen }),
  { closeSignInModal: closeSignInModalAction })(memo(ModalWrapper))
