import { CALC_OPTIONS, BOOKING, GET_REQUESTS, GET_VOLUNTEERS, PAYMENT_SUCCEED, CLOSE_MODAL } from './constants'
import CalculateService from '../CalculateService'


export const calcOptionAction = (slug, formData) => async (dispatch) => {

  const calcOptions = await CalculateService.calcOption(slug, formData)

  dispatch({
    type: CALC_OPTIONS,
    payload: calcOptions,
  })
}

export const bookingAction = (slug, formData) => async (dispatch) => {

  const calcOptions = await CalculateService.calcMissionFullPrice(slug, formData)
  const isModalOpen = true

  dispatch({
    type: BOOKING,
    payload: { calcOptions, isModalOpen },
  })
}

export const getRequestsAction = (page) => async (dispatch) => {
  try {
    const requests = await CalculateService.getRequests(page)

    dispatch({
      type: GET_REQUESTS,
      payload: requests,
    })
  } catch (e) {
    console.log(e)
  }
}

export const getVolunteersAction = (page, pageName = 'page_upcoming', searchValue = '') => async (dispatch) => {

  const volunteers = await CalculateService.getVolunteers(page, pageName, searchValue)

  dispatch({
    type: GET_VOLUNTEERS,
    payload: { ...volunteers, search: searchValue },
  })
}
export const succeedPaymentAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PAYMENT_SUCCEED,
      payload: id,
    })
  } catch (e) {
    console.log(e)
  }
}

export const acceptRequestAction = (requestId) => async () => CalculateService.acceptRequest(requestId)

export const declineRequestAction = (requestId) => async () => CalculateService.declineRequest(requestId)

export const acceptAllRequestsAction = () => async () => CalculateService.acceptAllRequests()

export const closeModalAction = (modalStatus) => async (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
    payload: modalStatus,
  })
}
