import React from 'react'
import { useTranslation } from 'react-i18next'

/* components */
import MailTo from 'components/partials/sheared/MailTo'
import NavLinks from 'components/partials/sheared/NavLinks'

/* styles */
import classes from './index.module.scss'

const Footer = () => {
  const { t } = useTranslation('footer_volunteering')
  return (
    <footer className={classes.footer}>
      <div className={classes.topFooterWrapper}>
        <div className="container">
          <div className={classes.topFooterInner}>

            <MailTo />

            <div className="d-flex">
              <NavLinks />
            </div>

          </div>
        </div>
      </div>

      <div className={classes.lowFooter}>
        <div className={classes.lowFooterWrapper}>
          <div className="container">
            <div className={classes.lowFooterInner}>

              <div className={classes.lowFooterCopyright}>
                {t('footerFirstText')} {new Date().getFullYear()} {t('footerSecondText')}
              </div>

            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}


export default Footer
