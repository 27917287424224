import { GET_PAYMENT_HISTORY } from './constatnts'

const initialState = {
  currentPage: 1,
  invoices: [],
}

const paymentReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_PAYMENT_HISTORY:
      return {
        ...state,
        invoices: payload.data,
        currentPage: payload.meta.current_page,
        total: payload.total,
        lastPage: payload.meta.last_page,
      }
    default:
      return state
  }
}

export default paymentReducer
