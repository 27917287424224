import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import classes from './Inputs.module.scss'

const Input = ({
  id,
  label,
  name,
  type,

  onChange,
  onBlur,
  value,

  placeholder,
  size,
  className,
  labelColor,
  errorInput,
  errorLabel,
  disabled,
  spacing,
  requiredField,
}) => {
  const { t } = useTranslation('common_volunteering')

  return (
    <label htmlFor={id} className={clsx(classes.blockWithInput, classes[spacing], className, 'd-flex')}>
      <div className="d-flex d-flex justify-content-between">
        <span className={clsx(classes[labelColor], classes.label, classes[errorLabel])}>
          {t(label)}
        </span>
        {requiredField && (
          <span className={clsx(classes.label, classes[errorLabel], 'd-flex justify-content-end')}>
            {t('mandatory')}
          </span>
        )}
      </div>
      <input
        className={clsx(classes.input, classes[size], classes[errorInput])}
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={t(placeholder)}
        disabled={disabled}
      />

    </label>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'numberInput']),
  labelColor: PropTypes.oneOf(['white', 'black']),
  spacing: PropTypes.oneOf(['mb-0', 'mb-2', 'mb-3', 'mb-4', 'mb-5']),
  onChange: PropTypes.func,
  errorInput: PropTypes.string,
  errorLabel: PropTypes.string,
  disabled: PropTypes.bool,
}

Input.defaultProps = {
  label: '',
  placeholder: '',
  className: 'flex-column',
  labelColor: 'black',
  type: 'text',
  spacing: 'mb-0',
}

export default memo(Input)
