import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

/* routes */
import routes from 'constants/routes'

/* actions */
import { openSignInModalAction } from '../../modules/app/store/actions'
import { isUserExistsSelector, isUserProfileFilledSelector } from '../../modules/currentUser/store/selectors'

const PrivateRoute = ({ component: Component, isUserExists, isUserProfileFilled, allowWithoutProfile, openSignInModal, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserExists) {
        openSignInModal()
        return <Redirect to={routes.home} />
      }
      if (isUserExists && !isUserProfileFilled) {
        if (allowWithoutProfile) {
          return <Component {...props} />
        }
        return <Redirect to={{ pathname: routes.createProfile }} />
      }
      return <Component {...props} />
    }}
  />
)

PrivateRoute.propTypes = {
  isUserExists: PropTypes.bool.isRequired,
  isUserProfileFilled: PropTypes.bool.isRequired,
  openSignInModal: PropTypes.func.isRequired,
  allowWithoutProfile: PropTypes.bool,
}

PrivateRoute.defaultProps = {
  allowWithoutProfile: false,
}

const mapStateToProps = (state) => ({
  isUserExists: isUserExistsSelector(state),
  isUserProfileFilled: isUserProfileFilledSelector(state),
})

const actionsToProps = {
  openSignInModal: openSignInModalAction,
}


export default connect(mapStateToProps, actionsToProps)(PrivateRoute)
