import { GET_CATEGORIES_LIST } from './constants'

const initialState = {
  categories: null,
}

const categoriesListReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_CATEGORIES_LIST:
      return {
        ...state,
        categories: payload,
      }
    default:
      return state
  }
}

export default categoriesListReducer
