import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'


/* import HomePage from 'pages/Home'
import ThemePage from 'pages/Theme'
import CreateHostPage from 'pages/CreateHost'
import HelpCenterPage from 'pages/HelpCenter'
import ProfileHostPage from 'pages/Profile/Host'
import ProfileVolunteerPage from 'pages/Profile/Volunteer'
import StructurePage from 'pages/Structure'
import MissionPage from 'pages/Mission'
import CreateProfile from 'pages/CreateProfile'
import NotFoundPage from 'pages/NotFoundPage'
import CreateMission from 'pages/CreateMission'
import Booking from 'pages/Booking'
import SearchMissionPage from 'pages/SearchMissionPage'
import Profile from 'dashboard/Profile'
import Settings from 'dashboard/Settings'
import EditMission          from '../pages/EditMission' */

import routes from 'constants/routes'
import PrivateRoute from './access/privateRoute'
import PublicRoute from './access/publicRoute'
import PreLoader from '../components/partials/PreLoader'

/* pages */
const HomePage = lazy(() => import('pages/Home'))
const ThemePage = lazy(() => import('pages/Theme'))
const CreateHostPage = lazy(() => import('pages/CreateHost'))
const HelpCenterPage = lazy(() => import('pages/HelpCenter'))
const ProfileHostPage = lazy(() => import('pages/Profile/Host'))
const ProfileVolunteerPage = lazy(() => import('pages/Profile/Volunteer'))
const StructurePage = lazy(() => import('pages/Structure'))
const MissionPage = lazy(() => import('pages/Mission'))
const CreateProfile = lazy(() => import('pages/CreateProfile'))
const NotFoundPage = lazy(() => import('pages/NotFoundPage'))
const CreateMission = lazy(() => import('pages/CreateMission'))
const Booking = lazy(() => import('pages/Booking'))
const SearchMissionPage = lazy(() => import('pages/SearchMissionPage'))
const Profile = lazy(() => import('dashboard/Profile'))
const Settings = lazy(() => import('dashboard/Settings'))
const EditMission = lazy(() => import('pages/EditMission'))
const Messenger = lazy(() => import('pages/Messenger'))
const PrivacyPage = lazy(() => import('pages/Privacy'))
const ConditionsPage = lazy(() => import('pages/Conditions'))


const AppModel = () => (
  <Suspense fallback={<PreLoader />}>
    <Switch>

      <PublicRoute exact path={routes.home} component={HomePage} />
      <PublicRoute exact path={routes.theme} component={ThemePage} />
      <PublicRoute exact path={routes.helpCenter} component={HelpCenterPage} />
      <PublicRoute exact path={routes.profileVolunteer} component={ProfileVolunteerPage} />
      <PublicRoute exact path={routes.profileHost} component={ProfileHostPage} />
      <PublicRoute exact path={routes.structure} component={StructurePage} />
      <PublicRoute exact path={routes.mission} component={MissionPage} />
      <PublicRoute exact path={routes.searchMission} component={SearchMissionPage} />
      <PublicRoute exact path={routes.privacyPolicy} component={PrivacyPage} />
      <PublicRoute exact path={routes.conditionsPage} component={ConditionsPage} />

      <PrivateRoute exact path={routes.createProfile} component={CreateProfile} allowWithoutProfile />
      <PrivateRoute exact path={routes.createHost} component={CreateHostPage} allowWithoutProfile />
      <PrivateRoute exact path={routes.dashboardProfile} component={Profile} />
      <PrivateRoute exact path={routes.dashboardSettings} component={Settings} />
      <PrivateRoute exact path={routes.createMission} component={CreateMission} />
      <PrivateRoute exact path={routes.booking} component={Booking} />
      <PrivateRoute exact path={routes.editMission} component={EditMission} />
      <PrivateRoute exact path={routes.messenger} component={Messenger} />

      <Route exact path="*" component={NotFoundPage} />
    </Switch>
  </Suspense>
)

export default AppModel
