import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* styles */
import classes from 'components/common/DynamicImage/index.module.scss'
import getImagePath from '../../../helpers/get-image-path'
import { IMAGE_VARIANT } from '../../../constants/images'

const DynamicImage = ({ imageData, variant, size, onClick, type }) => {
  const [errorsAttempts, setErrorsAttempts] = useState(0)
  const variation = variant || (imageData && imageData.default)
  const defaultSrc = `https://fakeimg.pl/${size || ''}?text=default`

  const imageSrc = getImagePath({ imageData, size, variant: variation })

  const addDefaultSrc = (e) => {
    e.target.src = defaultSrc
  }
  const onErrorLoadingImg = (e) => {
    /** If image variant is original, and it is not found with .jpg - try with .jpeg extension */
    if (errorsAttempts === 0) {
      if (imageData.extension === 'jpg') {
        setErrorsAttempts(errorsAttempts + 1)
        e.target.src = getImagePath({ imageData, size, variant: variation, extension: 'jpeg' })
        return
      }
    }
    /** If original image is not found - try cropped varian */
    if (errorsAttempts === 1) {
      setErrorsAttempts(errorsAttempts + 1)
      e.target.src = getImagePath({ imageData, size, variant: IMAGE_VARIANT.MEDIUM })
      return
    }
    /** Set default if image is not found */
    addDefaultSrc(e)
  }

  return (
    <div className={type === 'cards' ? classes.dynamicImageCards : classes.dynamicImageSlider}>
      <img
        role="button"
        className={classes.dynamicImage}
        src={imageSrc}
        alt="media"
        onError={onErrorLoadingImg}
        onClick={onClick}
      />
    </div>
  )
}

DynamicImage.propTypes = {
  imageData: PropTypes.shape({
    default: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
  }),
  variant: PropTypes.oneOf(['mini', 'medium', 'original']),
  size: PropTypes.string,
  type: PropTypes.oneOf(['cards', 'slider']),
}

DynamicImage.defaultProps = {
  type: 'cards',
}

export default DynamicImage
