import { volunteeringRequestHandler } from '../../api/http-client/request-handler'
import { currentUserTransformer, profileTransformer } from './transformers'

const CurrentUserService = {
  getCurrentUser() {
    return volunteeringRequestHandler.get('users/me').then(({ result }) => currentUserTransformer(result))
  },

  getProfileByUserId(userID) {
    return volunteeringRequestHandler.get(`${userID}/profile`).then(({ result }) => profileTransformer(result))
  },

  createProfile(formData) {
    return volunteeringRequestHandler.post('users/me', formData)
  },

  verifyAccount(data) {
    return volunteeringRequestHandler.post('users/upload-document', data)
  },
}

export default CurrentUserService
