import { APP_INIT, APP_SET_SIGN_IN_MODAL_STATE } from './constants'

const initialState = {
  isInitiated: false,
  isSignInModalOpen: false,
}

const appReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case APP_INIT: {
      return { ...state, isInitiated: true }
    }
    case APP_SET_SIGN_IN_MODAL_STATE: {
      return {
        ...state,
        isSignInModalOpen: payload,
      }
    }
    default:
      return state
  }
}

export default appReducer
