import React from 'react'

import './styles.scss'

const Loading = () => (
  <div className="loading">
    <div className="loadingio-spinner-eclipse-k9po4rx7kf">
      <div className="ldio-syjkdu0zrip">
        <div />
      </div>
    </div>
  </div>
)
export default Loading
