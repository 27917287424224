import { GET_MISSIONS_LIST, GET_MISSIONS_SEARCH } from './constants'

const initialState = {
  missionsList: null,
  missionsSearch: null,
  currentPage: 1,
  lastPage: null,
  total: null,
}

const missionListReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_MISSIONS_LIST:
      return {
        ...state,
        missionsList: payload,
      }
    case GET_MISSIONS_SEARCH:
      return {
        ...state,
        missionsSearch: payload.result,
        currentPage: payload.pagination.meta.current_page,
        lastPage: payload.pagination.meta.last_page,
        total: payload.pagination.meta.total,
      }
    default:
      return state
  }
}

export default missionListReducer
