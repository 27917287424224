import { GET_THEME } from './constants'

const initialState = {
  theme: null,
}

const currentThemeReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_THEME:
      return {
        ...state,
        theme: payload,
      }
    default:
      return state
  }
}

export default currentThemeReducer
