import { GET_LANGUAGES, GET_LANGUAGES_ERROR, GET_LANGUAGES_SUCCESS, SET_LANGUAGE } from './constants'

const initialState = {
  currentLanguage: {
    id: 1,
    value: 1,
    label: 'English',
    code: 'en',
  },
  isLoading: true,
  languages: [],
}

const LanguageReducer = (state = { ...initialState }, {
  type,
  payload,
}) => {
  switch (type) {
    case SET_LANGUAGE:
      return {
        ...state,
        currentLanguage: { ...payload },
      }
    case GET_LANGUAGES:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: payload,
        isLoading: false,
      }
    case GET_LANGUAGES_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    default:
      return state
  }
}

export default LanguageReducer
