import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import clsx from 'clsx'

/* components */
import MailTo from 'components/partials/sheared/MailTo'
import NavLinks from 'components/partials/sheared/NavLinks'
import Logo from 'components/partials/Header/Logo'
import UnauthorizedActions from 'components/partials/Header/ActionsBlock/Unauthorized'
import AuthorizedActions from 'components/partials/Header/ActionsBlock/Authorized'

/* utils */
import MQ from 'utils/mq'
import { ForgotPasswordContext } from '../../../utils/ForgotPasswordContext'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'
import _useToggle from 'hooks/use-toggle'
import _useCustomEventListener from 'hooks/use-custom-event-listener'

/* styles */
import classes from 'components/partials/Header/index.module.scss'
import LanguageSwitcher from './LanguageSwitcher'
import Icon from '../../common/Icon'
import MobileMenu from './MobileMenu'
import { modalVisibilityActions } from '../../common/Modal/constants'
import routes from '../../../constants/routes'
import MessagesCount from '../../ui/MessagesCount'

/* actions */
import { getConversationListAction } from '../../../modules/messaging/store/actions'
import { getRequestsAction } from '../../../modules/booking/store/actions'
import customEvent from '../../../utils/custom-event'


const Header = ({ user, getConversationList, getRequests, hash }) => {
  const [isForgotPassword, setIsForgotPassword] = useState(false)

  _useDidMount(() => {
    if (user.profile) {
      getConversationList()
      getRequests()
    }
  })

  _useDidMount(() => {
    if (hash) {
      const openModal = setTimeout(() => {
        customEvent.trigger(`${modalVisibilityActions.open}sign-in`)
      }, 500)
      // eslint-disable-next-line no-use-before-define
      onMenuClick()
      return () => {
        clearTimeout(openModal)
      }
    }
  })

  _useDidUpdate(() => {
    if (user.userId) {
      getConversationList()
    }
  }, [user.userId])

  const [mainToggle, onMainClick] = _useToggle(false)
  const onMenuClick = () => {
    onMainClick()
    if (!mainToggle) document.body.classList.add(classes.offScroll)
    else document.body.classList.remove(classes.offScroll)
  }
  _useCustomEventListener(`${modalVisibilityActions.close}sign-in`, onMenuClick)
  _useCustomEventListener(`${modalVisibilityActions.close}sign-up`, onMenuClick)
  return (
    <header className={clsx(classes.header)}>

      <MQ.SM_UP>
        <div className={classes.topHeaderWrapper}>
          <div className="container">
            <div className={classes.topHeaderInner}>

              <MQ.LG_UP>
                <MailTo />
              </MQ.LG_UP>

              <div className={classes.topHeaderRightSide}>
                <NavLinks />
                <LanguageSwitcher desktop />
              </div>

            </div>
          </div>
        </div>
      </MQ.SM_UP>

      <ForgotPasswordContext.Provider value={{isForgotPassword, setIsForgotPassword}}>
        <div className={classes.lowHeaderWrapper}>
          <div className="container">
            <div className={classes.lowHeaderInner}>

              <Logo />
              <MQ.SM_UP>
                {user && user.userId ? (
                  <AuthorizedActions userData={user} />
                ) : (
                  <UnauthorizedActions />
                )}
              </MQ.SM_UP>
              <MQ.SM_DOWN>
                <div>
                  {user.profile && (
                    <Link to={{ pathname: formatRoute(routes.messenger) }} className={classes.messengerLink}>
                      <MessagesCount />
                      <Icon iconName="icon_messenger" />
                    </Link>
                  )}
                  <Icon
                    extension="png"
                    iconName="icon_menu"
                    size="20x20"
                    onClick={onMenuClick}
                  />
                </div>

                <MobileMenu
                  user={user}
                  onMainClick={onMenuClick}
                  mainToggle={mainToggle}
                  hash={hash}
                />
              </MQ.SM_DOWN>
            </div>
          </div>
        </div>
      </ForgotPasswordContext.Provider>

    </header>
  )
}

const mapStateToProps = (state) => ({
  user: { ...state.currentUser.user, profile: state.currentUser.profile },
  hash: state.router.location.hash,
})

const actionsToProps = {
  getConversationList: getConversationListAction,
  getRequests: getRequestsAction,
}

export default connect(mapStateToProps, actionsToProps)(Header)
