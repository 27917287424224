import React from 'react'
import { Link } from 'react-router-dom'

/* constants */
import routes from 'constants/routes'

/* styles */
import classes from 'components/partials/Header/Logo/index.module.scss'

/* assets */
import newLogo from 'assets/images/newLogo.jpg'

const Logo = () => (
  <div className={classes.wrapper}>
    <Link className={classes.link} to={routes.home}>
      <div className={classes.logoWrapper}>
        <img
          className={classes.image}
          src={newLogo}
          alt="logo"
        />
      </div>
    </Link>
  </div>
)

export default Logo
