import { GET_COUNTRIES, GET_SHIPPING_ADDRESS } from './constants'

const initialState = {
  countries: null,
  shippingAddress: null,
}

const countriesReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      }
    case GET_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: payload,
      }
    default:
      return state
  }
}

export default countriesReducer
