import { CALC_OPTIONS, BOOKING, GET_REQUESTS, GET_VOLUNTEERS, PAYMENT_SUCCEED, CLOSE_MODAL } from './constants'

const initialState = {
  cost: null,
  booking: null,
  isModalOpen: false,
  requests: null,
  volunteers: null,
}

const calcOptionReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case CALC_OPTIONS:
      return {
        ...state,
        cost: payload,
      }
    case BOOKING:
      return {
        ...state,
        booking: payload.calcOptions,
        isModalOpen: payload.isModalOpen,
      }
    case GET_REQUESTS:
      return {
        ...state,
        requests: payload,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: payload,
      }
    case GET_VOLUNTEERS:
      return {
        ...state,
        volunteers: payload,
      }
    case PAYMENT_SUCCEED: {
      const request = [...state.requests.result]
      const volunteers = [...state.volunteers.result.upcoming]

      const deleted = request.find(((value) => value.id === Number(payload)))
      deleted.status = 'done'
      volunteers.push(deleted)

      const newRequest = request.filter(((value) => value.id !== Number(payload)))
      return {
        ...state,
        requests: {
          ...state.requests,
          result: newRequest,
        },
        volunteers: {
          result: {
            ...state.volunteers.result,
            upcoming: volunteers,
          },
        },
      }
    }
    default:
      return state
  }
}

export default calcOptionReducer
