import React, { PureComponent, useEffect } from 'react'
import { Router, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from 'store'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

/* routes-model */
import AppModel from 'routes/app-model'

/* components */
import Header from 'components/partials/Header'
import Footer from 'components/partials/Footer'
import Loading from './components/partials/Loading'

/* actions */
import { initAppAction } from './modules/app/store/actions'
// import FacebookSDK from './3d-party/facebookSDK'
import OpenGraph from './components/OpenGraph'

// TODO probably it can be used not via jsx
function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

class App extends PureComponent {

  async componentDidMount() {
    const { isInitiated, initApp } = this.props

    if (!isInitiated) {
      await initApp()
    }
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { isInitiated } = this.props

    if (!isInitiated) {
      return (
        <Loading />
      )
    }
    return (
      <Router history={history}>
        <ScrollToTop />

        <OpenGraph
          title="Volunteering Platform"
          isDefaultPage
        />

        <Header />
        <div className="root">
          <AppModel />
        </div>


        <Footer />

        <ToastContainer
          hideProgressBar
          pauseOnHover={false}
          position="bottom-left"
        />

        <Loading />
        {/* <FacebookSDK /> */}
      </Router>
    )
  }

}

export default connect(
  (
    {
      app: { isInitiated },
    },
  ) => ({
    isInitiated,
  }), {
    initApp: initAppAction,
  },
)(App)
