import { GET_CURRENT_VOLUNTEER_PROFILE, GET_VOLUNTEER_FAVORITES_MISSION } from './constants'
import VolunteerService from '../VolunteerService'


export const getCurrentVolunteerProfileAction = (id) => async (dispatch) => {
  const currentVolunteer = await VolunteerService.getVolunteer(id)

  dispatch({
    type: GET_CURRENT_VOLUNTEER_PROFILE,
    payload: currentVolunteer,
  })
}

export const modifyVolunteerProfileInfoAction = (id, formData) => async () => (
  VolunteerService.modifyVolunteerProfileInfo(id, formData)
)

export const getVolunteerFavoritesMissionsAction = (page) => async (dispatch) => {
  const volunteerFavoritesMissions = await VolunteerService.getVolunteerFavoritesMissions(page)

  dispatch({
    type: GET_VOLUNTEER_FAVORITES_MISSION,
    payload: volunteerFavoritesMissions,
  })
}

export const addVolunteerCommentAction = (volunteerId, data) => async () => {
  await VolunteerService.addComment(volunteerId, data)
}
