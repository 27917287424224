import { GET_MISSION_VOLUNTEERS_LIST } from './constants'

const initialState = {
  volunteers: [],
  currentPage: 1,
  lastPage: null,
}

const missionVolunteersReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_MISSION_VOLUNTEERS_LIST:
      return {
        ...state,
        volunteers: payload.result,
        currentPage: payload.pagination.meta.current_page,
        lastPage: payload.pagination.meta.last_page,
      }
    default:
      return state
  }
}

export default missionVolunteersReducer
