import { SET_BOOKING_INFO } from './constants'

const initialState = {
}

const bookingInfoReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_BOOKING_INFO:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

export default bookingInfoReducer
