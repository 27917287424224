import { COUNT_UNREAD_MESSAGES } from './constants'

const initialState = {
  unreadMessages: null,
}

const messengerReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case COUNT_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: payload,
      }
    default:
      return state
  }
}

export default messengerReducer
