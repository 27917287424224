import React from 'react'
import style from './index.module.scss'

const PreLoader = () => (
  <div className={style.overlay}>
    <div className="loadingio-spinner-eclipse-k9po4rx7kf">
      <div className="ldio-syjkdu0zrip">
        <div />
      </div>
    </div>
  </div>
)
export default PreLoader
