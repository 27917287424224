import React from 'react'
import clsx from 'clsx'
import style from '../../MobileMenu.module.scss'
import Icon from '../../../../../common/Icon'
import SignInForm from '../../../../../../modules/auth/SignIn'
import SignUpForm from '../../../../../../modules/auth/SignUp'
import UserDropDown from '../../../ActionsBlock/Authorized/DropDown'
import customEvent from '../../../../../../utils/custom-event'
import { modalVisibilityActions } from '../../../../../common/Modal/constants'
import _useCustomEventListener from '../../../../../../hooks/use-custom-event-listener'

const MobileAuth = ({
  isForgotPassword,
  setIsForgotPassword,
  isOpen,
  currentForm,
  closeClick,
  user,
}) => {

  _useCustomEventListener(`${modalVisibilityActions.close}sign-in`, closeClick)
  _useCustomEventListener(`${modalVisibilityActions.close}sign-up`, closeClick)
  const renderForm = () => {
    switch (currentForm) {
      case 'login':
        return <SignInForm isForgotPassword={isForgotPassword} setIsForgotPassword={setIsForgotPassword} />
      case 'signUp':
        return <SignUpForm />
      case 'profile':
        return (
          <UserDropDown
            toggleParentDropDown={() => {
              customEvent.trigger(`${modalVisibilityActions.close}sign-in`)
            }}
            user={user}
          />
        )
      default: return null
    }

  }
  return (
    <div className={clsx(style.wrapper, style.formsWrapper, { [style.openMenu]: isOpen })}>
      <div
        className={style.goBackButton}
        role="button"
        onClick={closeClick}>
        <Icon iconFolder="arrows" iconName="arrow-left" />
      </div>
      {currentForm === 'profile' && <h1 className={style.profileTitle}>Profile</h1>}
      {renderForm()}
    </div>

  )
}
export default MobileAuth
