import { GET_CURRENT_STRUCTURE } from './constants'

const initialState = {
  structure: null,
}

const currentStructureReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_CURRENT_STRUCTURE:
      return {
        ...state,
        structure: payload,
      }
    default:
      return state
  }
}

export default currentStructureReducer
