import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './styles.scss'
import { renderConditionsFr } from './renderConditionsFr'
import { renderConditionsEn } from './renderConditionsEn'

const ConditionsPage = ({ lang }) => (
  <main className="main conditions-page">
    <div className="container">
      <div className="html-inner" dangerouslySetInnerHTML={{ __html: lang === 'fr' ? renderConditionsFr : renderConditionsEn }} />
    </div>
  </main>
)

ConditionsPage.propTypes = {
  lang: PropTypes.string,
}

ConditionsPage.defaultProps = {
  lang: 'en',
}

const mapStateToProps = (state) => ({
  lang: state.languages.currentLanguage?.code,
})
export default connect(mapStateToProps, null)(ConditionsPage)
