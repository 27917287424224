import { volunteeringRequestHandler } from '../../api/http-client/request-handler'
import { objToFormData } from '../../utils/object'


const VolunteerService = {
  getVolunteer(id) {
    return volunteeringRequestHandler.get(`${id}/profile`)
      .then(({ result }) => result)
  },

  modifyVolunteerProfileInfo(id, data1) {
    if (data1.avatar === null) {
      data1.avatar = ''
    }
    const data = { ...data1, phone_number_show: Number(data1.phone_number_show) }

    const formData = objToFormData({ data })

    return volunteeringRequestHandler.post(`${id}/profile`, formData)
      .then(({ result }) => result)
  },

  getVolunteerFavoritesMissions(page) {
    return volunteeringRequestHandler.get('users/favourite-mission', {
      params: {
        page: page || 1,
      },
    })
      .then()
  },

  addComment(volunteerId, data) {
    return volunteeringRequestHandler.post('profile/leave-comment', { id: volunteerId, ...data })
  },
}

export default VolunteerService
