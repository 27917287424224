export const options = [
  {
    value: 'en',
    label: 'English',
    flag: 'en',
  },
  {
    value: 'fr',
    label: 'French',
    flag: 'fr',
  },
  {
    value: 'de',
    label: 'Germany',
    flag: 'de',
  },
]
