import { toast } from 'react-toastify'
import { GET_LANGUAGES, GET_LANGUAGES_ERROR, GET_LANGUAGES_SUCCESS, SET_LANGUAGE } from './constants'
import { getLanguages } from '../languageService'

export const setLanguageAction = (lang) => (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: lang,
  })
}

export const getLanguagesAction = () => async (dispatch) => {
  dispatch({ type: GET_LANGUAGES })
  try {
    const { result } = await getLanguages()
    dispatch(
      {
        type: GET_LANGUAGES_SUCCESS,
        payload: result.map((lang) => {
          lang.label = lang.name
          lang.value = lang.id
          lang.code = lang.code.toLowerCase()
          return lang
        }),
      },
    )
  } catch (e) {
    dispatch(
      {
        type: GET_LANGUAGES_ERROR,
        payload: e,
      },
    )
    toast.error(e.message)
  }
}
