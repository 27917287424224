import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

/* components */
import Input from 'components/ui/Inputs/Base'
import PasswordInput from 'components/ui/Inputs/Password'
import Button from 'components/ui/Button/base'
import SectionTitle from 'components/ui/SectionTitle'
import FacebookAuth from 'modules/auth/FacebookAuth'

/* utils */
import customEvent from '../../../utils/custom-event'

/* constants */
import { formFields } from './constants'
import { modalVisibilityActions } from '../../../components/common/Modal/constants'

/* actions */
import { facebookAuthAction, signUpAction } from '../store/actions'


const SignUpForm = ({ signUp, facebookSignUp }) => {
  const closeThisModal = () => customEvent.trigger(`${modalVisibilityActions.close}sign-up`)

  const socialSignUp = (res) => {
    console.log('res Up', res)
    // const data = {
    //   token: res.accessToken,
    //   network: 'facebook',
    // }

    facebookSignUp(res)
    closeThisModal()
  }

  const formik = useFormik({
    initialValues: {
      [formFields.firstName]: '',
      [formFields.lastName]: '',
      [formFields.email]: '',
      [formFields.password]: '',
      [formFields.passwordConfirmation]: '',
    },

    validationSchema: Yup.object({
      [formFields.firstName]: Yup.string().required(),
      [formFields.lastName]: Yup.string().required(),
      [formFields.email]: Yup.string().email().required(),
      [formFields.password]: Yup.string().min(8).required(),
      [formFields.passwordConfirmation]: Yup.string().oneOf([Yup.ref('password'), null], 'passwords does not match').required('password confirmation is required field'),
    }),

    onSubmit: (values, { resetForm }) => {
      signUp(values)
        .then(() => {
          resetForm()
          closeThisModal()
        })
        .catch((error) => {
          if (error.response?.data?.validation?.email) toast.error(error.response.data.validation.email[0])
        })
    },
  })

  const hasError = (fieldName) => formik.touched[fieldName] && formik.errors[fieldName]
  const emailHasError = hasError(formFields.email)
  const fistNameHasError = hasError(formFields.firstName)
  const lastNameHasError = hasError(formFields.lastName)
  const passwordHasError = hasError(formFields.password)
  const passwordConfirmationHasError = hasError(formFields.passwordConfirmation)

  const isSignUpBtnDisabled = !formik.isValid || Object.keys(formFields).some((value) => !value)

  return (
    <form onSubmit={formik.handleSubmit}>
      <SectionTitle item="signUpFormTitle" />

      <FacebookAuth
        type="signup"
        callback={socialSignUp}
      />

      <Input
        value={formik.values[formFields.firstName]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}

        id={formFields.firstName}
        name={formFields.firstName}
        label="signUpFormFirstNameLabel"
        placeholder="signUpFormFirstNamePlaceholder"

        error={fistNameHasError ? formik.errors[formFields.firstName] : null}
        errorInput={fistNameHasError ? 'errorInput' : null}
        errorLabel={fistNameHasError ? 'errorLabel' : null}

        spacing="mb-5"
      />

      <Input
        value={formik.values[formFields.lastName]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}

        id={formFields.lastName}
        name={formFields.lastName}
        label="signUpFormLastNameLabel"
        placeholder="signUpFormLastNamePlaceholder"

        error={lastNameHasError ? formik.errors[formFields.lastName] : null}
        errorInput={lastNameHasError ? 'errorInput' : null}
        errorLabel={lastNameHasError ? 'errorLabel' : null}

        spacing="mb-5"
      />

      <Input
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}

        id={formFields.email}
        name={formFields.email}
        label={formFields.email}
        placeholder="formEmailPlaceholder"
        type={formFields.email}

        error={emailHasError ? formik.errors.email : null}
        errorInput={emailHasError ? 'errorInput' : null}
        errorLabel={emailHasError ? 'errorLabel' : null}

        spacing="mb-5"
      />

      <PasswordInput
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}

        id={formFields.password}
        name={formFields.password}
        label={formFields.password}
        placeholder="formPasswordPlaceholder"

        error={passwordHasError ? formik.errors.password : null}
        errorInput={passwordHasError ? 'errorInput' : null}
        errorLabel={passwordHasError ? 'errorLabel' : null}

        spacing="mb-5"
      />

      <PasswordInput
        value={formik.values.password_confirmation}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}

        id={formFields.passwordConfirmation}
        name={formFields.passwordConfirmation}
        label="signUpFormConfirmPasswordLabel"
        placeholder="signUpFormConfirmPasswordPlaceholder"

        error={passwordConfirmationHasError ? formik.errors[formFields.passwordConfirmation] : null}
        errorInput={passwordConfirmationHasError ? 'errorInput' : null}
        errorLabel={passwordConfirmationHasError ? 'errorLabel' : null}

        spacing="mb-5"
      />

      <Button
        text="signUpBtn"
        onClick={formik.handleSubmit}
        disabled={isSignUpBtnDisabled}

        type="submit"
        size="large"
        color="btn-primary"
        spacing="mb-6"
      />

    </form>
  )
}

SignUpForm.propTypes = {
  signUp: PropTypes.func.isRequired,
  facebookSignUp: PropTypes.func.isRequired,
}

SignUpForm.defaultProps = {}

export default connect(
  undefined,
  {
    signUp: signUpAction,
    facebookSignUp: facebookAuthAction,
  },
)(SignUpForm)
