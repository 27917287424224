import axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL } from 'constants/main'
import AuthService from '../../modules/auth/AuthService'

const body = document.getElementsByTagName('body')

const requestInterceptor = (config) => {
  const headers = { ...config.headers }

  if (!headers.Authorization && AuthService.hasToken()) {
    headers.Authorization = AuthService.getToken()
  }

  if (config.method === 'get') {
    body[0].classList.add('loading-overlay')
  }

  return ({
    ...config,
    headers,
  })
}

const responseInterceptor = ({ data }) => {
  if (data.messages && data.messages.length) {
    const message = data.messages[0].text
    // eslint-disable-next-line no-console
    console.log('message', message)
  }

  body[0].classList.remove('loading-overlay')

  return data
}

const responseInterceptorError = (error) => {

  body[0].classList.remove('loading-overlay')
  if (error?.response?.data?.messages) {
    error.response.data.messages.forEach((message) => {
      toast[message.severity](message.text)
    })
  }
  throw error
}

const defaultOptions = { isVolunteering: false }

const createRequestHandler = (options = {}) => {
  const { isVolunteering } = {
    ...defaultOptions,
    ...options,
  }

  const urlPrefix = isVolunteering ? 'volunteering' : ''
  const baseUrl = new URL(urlPrefix, API_URL)
  const instance = axios.create({ baseURL: baseUrl.href })

  instance.interceptors.request.use(requestInterceptor)
  instance.interceptors.response.use(responseInterceptor, responseInterceptorError)

  return instance
}

export const baseRequestHandler = createRequestHandler()
export const volunteeringRequestHandler = createRequestHandler({ isVolunteering: true })
