import { replace } from 'connected-react-router'
// import { toast } from 'react-toastify'
import { formatRoute } from 'react-router-named-routes'
import routes from '../../../constants/routes'
import { SIGN_OUT, ADD_COMMENT } from './constants'
import AuthService from '../AuthService'
import { syncCurrentUserAction } from '../../currentUser/store/actions'
import { volunteeringRequestHandler } from '../../../api/http-client/request-handler'

export const initAuthAction = () => async (dispatch) => {
  if (AuthService.hasToken()) {
    await dispatch(syncCurrentUserAction())
  }
}

const getCurrentProfile = async (dispatch) => {
  const { user } = await dispatch(syncCurrentUserAction())

  if (!user.isProfileFilled) {
    dispatch(replace(routes.createProfile))
  } else {
    dispatch(replace(formatRoute(routes.dashboardProfile, { tab: 'personal-info' })))
  }
}

export const signInAction = (formData) => async (dispatch) => {
  await AuthService.signIn(formData)
  await getCurrentProfile(dispatch)
}

export const signUpAction = (formData) => async (dispatch) => {
  await AuthService.signUp(formData)
  await getCurrentProfile(dispatch)
}

export const signOutAction = () => (dispatch) => {
  AuthService.signOut()
  dispatch({
    type: SIGN_OUT,
  })
  dispatch(replace(routes.home))
}

export const prefillFacebookAction = (data) => (/* dispatch */) => {
  volunteeringRequestHandler
    .post('auth/social/prefill', data)
    .then(({ result }) => {
      // eslint-disable-next-line no-console
      console.log('userData prefill', result)

      // dispatch(facebookAuth(data))

    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('prefill error', error)
    })
}

export const facebookAuthAction = (formData) => async (dispatch) => {
  await AuthService.facebookAuth(formData)

  const { user } = await dispatch(syncCurrentUserAction())

  if (!user.isProfileFilled) {
    dispatch(replace(routes.createProfile))
  } else {
    dispatch(replace(routes.dashboardProfile))
  }
}

export const addComment = () => async (dispatch) => dispatch({
  type: ADD_COMMENT,
  payload: {},
})
