export const currentUserTransformer = (
  {
    id: userId,
    first_name: firstName,
    last_name: lastName,
    profile_filled: isProfileFilled,
    twaming_account: isTwamingAccount,
    ...rest
  },
) => (
  {
    ...rest,
    userId,
    firstName,
    lastName,
    isProfileFilled,
    isTwamingAccount,
  }
)

export const profileTransformer = (
  {
    user,
    id: profileId,
    date_of_birth: dateOfBirth,
    verified_account: isAccountVerified,
    verified_email: isEmailVerified,
    ...rest
  },
) => (
  {
    ...rest,
    user: currentUserTransformer(user),
    profileId,
    dateOfBirth,
    isAccountVerified,
    isEmailVerified,
  }
)
