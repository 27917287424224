import { ASSETS_URL } from '../constants/main'
import { IMAGE_VARIANT } from '../constants/images'

const getImagePath = ({ imageData, variant, size, extension = '' }) => {
  const variation = variant || (imageData && imageData.default)
  const defaultSrc = `https://fakeimg.pl/${size || ''}`
  const customExtension = extension || imageData?.extension

  if (variation === IMAGE_VARIANT.ORIGINAL) {
    return variation && imageData && imageData.path && customExtension
      ? `${ASSETS_URL}/${imageData.path}_${variation}.${customExtension}`
      : defaultSrc
  }

  return variation && imageData && imageData.path && imageData.extension
    ? `${ASSETS_URL}/${imageData.path}_${variation}_mdpi.${imageData.extension}`
    : defaultSrc
}

export default getImagePath
