import { createStore, compose, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'

import createRootReducer from 'store/reducers/root-reducer'

/* env */
import { ENV } from 'constants/main'

const composeEnhancers = typeof window === 'object'
// eslint-disable-next-line no-underscore-dangle
&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  // eslint-disable-next-line no-underscore-dangle
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension's options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose

const middlewares = [thunk]

const history = createBrowserHistory()

if (ENV === 'development') {
  middlewares.push(logger)
}

const store = createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      ...middlewares,
    ),
  ),
)

if (module.hot) {
  module.hot.accept('./reducers/root-reducer', () => {
    // eslint-disable-next-line global-require
    const createRootReducerHot = require('./reducers/root-reducer').default
    store.replaceReducer(createRootReducerHot(history))
  })
}

export { store, history }
