import { format } from 'date-fns'

import { volunteeringRequestHandler } from '../../api/http-client/request-handler'


const CalculateService = {

  calcOption(slug, formData) {
    const data = {
      ...formData,
      dates: {
        start_date: format(formData.dates.start_date, 'yyyy-MM-dd'),
        end_date: format(formData.dates.end_date, 'yyyy-MM-dd'),
      },
      adult: JSON.stringify(formData.adult),
      child: JSON.stringify(formData.child),
    }
    return volunteeringRequestHandler.post(`mission/${slug}/booking/calculate`, data)
      .then(({ result }) => result)
  },

  calcMissionFullPrice(slug, formData) {
    const data = {
      ...formData,
      dates: {
        start_date: format(formData.dates.start_date, 'yyyy-MM-dd'),
        end_date: format(formData.dates.end_date, 'yyyy-MM-dd'),
      },
      adult: JSON.stringify(formData.adult),
      child: JSON.stringify(formData.child),
    }
    return volunteeringRequestHandler.post(`mission/${slug}/booking`, data)
      .then(({ result }) => result)
  },

  getRequests(page) {
    return volunteeringRequestHandler.get('/booking', {
      params: {
        page: page || 1,
        status: ['pending', 'accept', 'failure'],
      },
    })
  },

  acceptAllRequests() {
    return volunteeringRequestHandler.post('/booking/accept-all')
  },

  getVolunteers(page, pageName, searchValue = ' ') {
    return volunteeringRequestHandler.get('/users/mission-requests', {
      params: {
        [`${pageName}`]: page || 1,
        search: searchValue,
      },
    })
  },

  acceptRequest(requestId) {
    return volunteeringRequestHandler.post(`/booking/${requestId}/accept`)
  },

  declineRequest(requestId) {
    return volunteeringRequestHandler.post(`/booking/${requestId}/decline`)
  },
}

export default CalculateService
