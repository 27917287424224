import React, { useContext, useState } from 'react'
import clsx from 'clsx'
import NavLinks from '../../sheared/NavLinks'
import LanguageSwitcher from '../LanguageSwitcher'
import Icon from '../../../common/Icon'
import style from './MobileMenu.module.scss'
import Button from '../../../ui/Button/base'
import MailTo from '../../sheared/MailTo'
import MobileAuth from './components/MobileAuth'
import _useToggle from '../../../../hooks/use-toggle'
import DynamicImage from '../../../common/DynamicImage'
import _useDidMount from '../../../../hooks/lifecycle/use-did-mount'
import { ForgotPasswordContext } from '../../../../utils/ForgotPasswordContext'


const MobileMenu = ({
  onMainClick,
  mainToggle,
  user,
  hash,
}) => {
  const [currentForm, setCurrentForm] = useState('')
  const [openAuth, onClickAuth] = _useToggle(false)
  const {isForgotPassword, setIsForgotPassword} = useContext(ForgotPasswordContext)

  _useDidMount(() => {
    if (hash) {
      onClickAuth()
      setCurrentForm('login')
    }
  }, [hash])

  return (
    <div className={clsx(style.wrapper, { [style.openMenu]: mainToggle })}>
      <header className={style.header}>
        <LanguageSwitcher />
        <Icon
          iconName="Union"
          onClick={() => {
            onMainClick()
            openAuth && onClickAuth()
            isForgotPassword && setIsForgotPassword(false)
          }}
        />
      </header>
      <div className={style.body}>
        {user && user.userId && (
          <div
            role="button"
            className={style.user}
            onClick={() => {
              setCurrentForm('profile')
              onClickAuth()
            }}>
            <div className={style.infoWrapper}>
              <div className={style.avatarWrapper}>
                <DynamicImage imageData={user.avatar} size="40x40" variant="medium" />
              </div>
              <div>{user.firstName}</div>
            </div>
            <Icon size="10x16" iconName="arrow-right-v2" />
          </div>
        )}
        <div className="mt-10">
          <NavLinks onItemClick={onMainClick} isHeader />
        </div>
        {!user.userId && (
          <div className={style.auth}>
            <Button
              type="button"
              text="signInBtn"
              size="small"
              spacing="mr-4"
              onClick={() => {
                setCurrentForm('login')
                isForgotPassword && setIsForgotPassword(false)
                onClickAuth()
              }}
            />
            <Button
              type="button"
              text="signUpBtn"
              size="small"
              onClick={() => {
                setCurrentForm('signUp')
                onClickAuth()
              }}
            />
          </div>
        )}
        <MobileAuth
          isForgotPassword={isForgotPassword}
          setIsForgotPassword={setIsForgotPassword}
          isOpen={openAuth}
          closeClick={onClickAuth}
          currentForm={currentForm}
          user={user}
        />
      </div>
      <footer className={style.footer}>
        <MailTo />
      </footer>
    </div>
  )
}
export default MobileMenu
