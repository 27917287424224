import React from 'react'
import MediaQuery from 'react-responsive'

/* constants */
import * as screenSizes from 'constants/screen-sizes'

export default {
  XL_DOWN: (props) => <MediaQuery {...props} maxWidth={screenSizes.XL_DOWN} />,
  XL_UP: (props) => <MediaQuery {...props} minWidth={screenSizes.XL_UP} />,
  LG_DOWN: (props) => <MediaQuery {...props} maxWidth={screenSizes.LG_DOWN} />,
  LG_UP: (props) => <MediaQuery {...props} minWidth={screenSizes.LG_UP} />,
  MD_DOWN: (props) => <MediaQuery {...props} maxWidth={screenSizes.MD_DOWN} />,
  MD_UP: (props) => <MediaQuery {...props} minWidth={screenSizes.MD_UP} />,
  SM_DOWN: (props) => <MediaQuery {...props} maxWidth={screenSizes.SM_DOWN} />,
  SM_UP: (props) => <MediaQuery {...props} minWidth={screenSizes.SM_UP} />,
  XS_DOWN: (props) => <MediaQuery {...props} maxWidth={screenSizes.XS_DOWN} />,
  XS_UP: (props) => <MediaQuery {...props} minWidth={screenSizes.XS_UP} />,
  XXS_DOWN: (props) => <MediaQuery {...props} maxWidth={screenSizes.XXS_DOWN} />,
  XXS_UP: (props) => <MediaQuery {...props} minWidth={screenSizes.XXS_UP} />,
}
