import { APP_INIT, APP_SET_SIGN_IN_MODAL_STATE } from './constants'
import { initAuthAction } from '../../auth/store/actions'

export const appInitiated = () => ({
  type: APP_INIT,
})

export const openSignInModalAction = () => (dispatch) => {
  dispatch({ type: APP_SET_SIGN_IN_MODAL_STATE, payload: true })
}

export const closeSignInModalAction = () => (dispatch) => {
  dispatch({ type: APP_SET_SIGN_IN_MODAL_STATE, payload: false })
}

export const initAppAction = () => async (dispatch) => {
  try {
    await Promise.all([
      dispatch(initAuthAction()),
    ])
  } catch (error) {
    // TODO: Implement error handling
  }

  dispatch(appInitiated())
}
