import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

/* routes */
import routes from 'constants/routes'

/* selectors */
import { isUserExistsSelector, isUserProfileFilledSelector } from '../../modules/currentUser/store/selectors'
import ConditionsPage from '../../pages/Conditions'
import PrivacyPage from '../../pages/Privacy'

const PublicRoute = ({ component: Component, isUserExists, isUserProfileFilled, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isUserExists && !isUserProfileFilled) {
        if (rest.path === routes.createProfile) {
          return <Component {...props} />
        }
        if (rest.path === routes.conditionsPage) {
          return <ConditionsPage />
        }
        if (rest.path === routes.privacyPolicy) {
          return <PrivacyPage />
        }
        return <Redirect to={{ pathname: routes.createProfile }} />
      }
      return <Component {...props} />
    }}
  />
)

PublicRoute.propTypes = {
  isUserExists: PropTypes.bool.isRequired,
  isUserProfileFilled: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isUserExists: isUserExistsSelector(state),
  isUserProfileFilled: isUserProfileFilledSelector(state),
})

export default connect(mapStateToProps)(PublicRoute)
