import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

/* selectors */
import { getRequests } from '../../../../../modules/booking/store/selectors'

/* styles */
import classes from './index.module.scss'


const RequestsCount = ({
  requests,
  title,
}) => {
  const { t } = useTranslation('common_volunteering')

  return (
    <div className={classes.tabWrapper}>
      <div className={classes.tab__title}>
        {t(title)}
      </div>
      {requests?.pagination?.meta.total > 0 && (
        <div className={classes.tab__count}>{requests.pagination.meta.total}</div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  requests: getRequests(state),
})

export default connect(mapStateToProps)(RequestsCount)
