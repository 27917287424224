import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import appReducer from '../../modules/app/store/reducer'
import currentUserReducer from '../../modules/currentUser/store/reducer'
import currentVolunteerReducer from '../../modules/volunteer/store/reducer'
import currentHostReducer from '../../modules/host/store/reducer'
import currentStructureReducer from '../../modules/structure/store/reducer'
import currentMissionReducer from '../../modules/mission/store/reducer'
import currentThemeReducer from '../../modules/theme/store/reducer'
import missionListReducer from '../../modules/missionsList/store/reducer'
import testimonialsListReducer from '../../modules/testimonials/store/reducer'
import categoriesListReducer from '../../modules/categories/store/reducer'
import missionVolunteersReducer from '../../modules/volunteerList/store/reducer'
import bookingInfoReducer from '../../modules/pages/Mission/sections/BodyMission/Overview/booking/store/reducer'
import calcOptionReducer from '../../modules/booking/store/reducer'
import countriesReducer from '../../modules/paymentInfo/store/reducer'
import paymentReducer from '../../modules/Payment/store/reducer'
import messengerReducer from '../../modules/messaging/store/reducer'
import LanguageReducer from '../../modules/currentLanguage/store/reducer'


const createRootReducer = (history) => combineReducers({
  app: appReducer,
  currentUser: currentUserReducer,
  currentVolunteer: currentVolunteerReducer,
  currentHost: currentHostReducer,
  currentStructure: currentStructureReducer,
  currentMission: currentMissionReducer,
  currentTheme: currentThemeReducer,
  missionList: missionListReducer,
  testimonialsList: testimonialsListReducer,
  categoriesList: categoriesListReducer,
  missionVolunteers: missionVolunteersReducer,
  calcOption: calcOptionReducer,
  bookingInfo: bookingInfoReducer,
  countries: countriesReducer,
  payment: paymentReducer,
  messenger: messengerReducer,
  languages: LanguageReducer,
  router: connectRouter(history),
})

export default createRootReducer
