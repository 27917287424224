import { GET_CURRENT_VOLUNTEER_PROFILE, GET_VOLUNTEER_FAVORITES_MISSION, DELETE_MISSION_FROM_FAVORITES } from './constants'

const initialState = {
  profile: null,
  favoritesMission: null,
}

const currentVolunteerReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GET_CURRENT_VOLUNTEER_PROFILE:
      return {
        ...state,
        profile: payload,
      }
    case GET_VOLUNTEER_FAVORITES_MISSION:
      return {
        ...state,
        favoritesMission: payload,
      }
    case DELETE_MISSION_FROM_FAVORITES:
      const newFavoritesMission = { ...state.favoritesMission }
      const deleteIndex = newFavoritesMission.result.findIndex((value) => value.id === payload)
      newFavoritesMission.result.splice(deleteIndex, 1)
      return {
        ...state,
        favoritesMission: newFavoritesMission,
      }
    default:
      return state
  }
}

export default currentVolunteerReducer
