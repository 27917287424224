import React from 'react'

/* components */
import Icon from 'components/common/Icon'

/* styles */
import classes from './index.module.scss'

const MailTo = () => (
  <div className={classes.mailToLinkWrapper}>
    <Icon iconName="email" />
    <a
      className={classes.mailToLink}
      href="mailto:info@travelwithamission.org">
      info@travelwithamission.org
    </a>
  </div>
)

export default MailTo
