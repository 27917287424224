import MessagingService from '../MessagingService'
import { COUNT_UNREAD_MESSAGES } from './constants'


export const getConversationListAction = (page, search) => async (dispatch) => MessagingService.getConversationList(page, search).then(
  (response) => {
    if (response.result.length) {
      dispatch({
        type: COUNT_UNREAD_MESSAGES,
        payload: response.result.map((chat) => chat.unread_messages).reduce((a, b) => (a + b)),
      })
    } else {
      return response
    }
    return response
  },
)

export const createChatWithUserAction = (userId) => async () => MessagingService.createChatWithUser(userId)

export const getChatRoomAction = (conversationId) => async () => MessagingService.getChatRoom(conversationId)

export const getMessagesAction = (conversationId, messagesPage) => async () => MessagingService.getMessages(conversationId, messagesPage)

export const sendMessageAction = (conversationId, message) => async () => MessagingService.sendMessage(conversationId, message)
