import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* components */
import Icon from 'components/common/Icon'

/* styles */
import clsx from 'clsx'
import classes from './index.module.scss'
import _useDidMount from '../../../hooks/lifecycle/use-did-mount'
import _useDidUpdate from '../../../hooks/lifecycle/use-did-update'

const FacebookAuth = ({ type, callback }) => {
  const [facebookProfileData, setFacebookProfileData] = useState(null)
  _useDidMount(() => {
    if (window.FB && !facebookProfileData) {
      window.FB.getLoginStatus((response) => {
        // console.log('status Check', response)

        if (response.status === 'connected') {
          window.FB.api('/me?fields=id,name,email', (response) => {
            setFacebookProfileData(response)
          })

          // window.FB.logout((response) => {
          //   console.log('Logout', response)
          // })
        }
      })
    }
  })

  _useDidUpdate(() => {
    // console.log('facebookProfileData', facebookProfileData)
  }, [facebookProfileData])

  const handleClick = () => {
    if (window.FB) {
      window.FB.login((response) => {
        console.log('login resp', response)
        if (response.status === 'connected') {
          const authData = {
            token: response?.authResponse?.accessToken,
            network: 'facebook',
          }
          window.FB.api('/me?fields=id,name,email', (response) => {
            console.log(`Successful login for: ${response.name}`)
            callback({ ...response, ...authData })
          })
        }
      }, { scope: 'public_profile,email' })
    }
  }

  return (
    <>
      <button
        type="button"
        className={clsx(classes.facebookButton, 'fb-login-button')}
        data-auto-logout-link="false"
        data-use-continue-as="false"
        onClick={() => handleClick()}>
        <Icon iconName="facebook-v2" />
        <span className={classes.facebookButtonText}>
          {type === 'signin' && 'Sign In '}
          {type === 'signup' && 'Sign Up '}
          via Facebook
        </span>
      </button>
    </>
  )
}

FacebookAuth.propTypes = {
  type: PropTypes.oneOf(['signin', 'signup']),
  callback: PropTypes.func,
}

export default FacebookAuth
