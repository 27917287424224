export const XL_UP = 1400
export const XL_DOWN = 1399

export const LG_UP = 1200
export const LG_DOWN = 1199

export const MD_UP = 992
export const MD_DOWN = 991

export const SM_UP = 768
export const SM_DOWN = 767

export const XS_UP = 650
export const XS_DOWN = 649

export const XXS_UP = 450
export const XXS_DOWN = 449
