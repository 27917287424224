import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import Select, { components } from 'react-select'

import SpriteIcon from 'components/icons/SpriteIcon'

/* translates */
import translates from 'translates/translate'

/* actions */

/* configs */
import { options } from './configs'

/* styles */
import './index.scss'
import { useLanguage } from '../../../../hooks/useLanguage'
import useDidMount from '../../../../hooks/lifecycle/use-did-mount'

const { Option, SingleValue } = components

const CustomOption = ({
  data: {
    code,
    label,
  },
  ...props
}) => (
  <Option {...props}>
    <SpriteIcon name={code} />
    {label}
  </Option>
)

const CustomSingleValue = ({
  data: {
    code,
    label,
  },
  ...props
}) => (
  <SingleValue {...props}>
    <SpriteIcon name={code} />
    {label}
  </SingleValue>
)

const LanguageSwitcher = ({
  desktop,
}) => {
  const {
    languages,
    currentLanguage,
    setLanguage,
  } = useLanguage()
  const changeLanguage = (lang) => {
    setLanguage(lang)
  }

  useDidMount(() => {
    setLanguage(currentLanguage)
  })

  useEffect(() => {
    translates.changeLanguage(currentLanguage.code.toLowerCase())
  }, [currentLanguage])
  return (
    <Select
      components={{
        IndicatorSeparator: null,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      defaultValue={options[0]}
      options={languages}
      value={currentLanguage}
      onChange={changeLanguage}
      // className="language-select"
      className={desktop ? 'language-select-desktop' : 'language-select'}
      classNamePrefix="language-option"
    />
  )
}


export default (withTranslation('common_volunteering')(LanguageSwitcher))
