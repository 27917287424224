import React from 'react'
import { connect } from 'react-redux'

/* style */
import classes from './index.module.scss'

const MessagesCount = ({
  messagesCount,
}) => (
  <>
    {messagesCount > 0 && (
      <div className={classes.unreadMessagesWrapper}>
        <div className={classes.unreadMessages}>
          {messagesCount}
        </div>
      </div>
    )}
  </>
)

const mapStateToProps = (state) => ({
  messagesCount: state.messenger.unreadMessages,
})

export default connect(mapStateToProps)(MessagesCount)
