import i18next from 'i18next'
import HttpApi from 'i18next-http-backend'
import axios from 'axios'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { API_URL } from 'constants/main'

let missingTimeout = null
const missing = {}

i18next
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: `${API_URL}/locales/{{lng}}/{{ns}}.json`,
      allowMultiLoading: false,
      crossDomain: true,
      withCredentials: false,
    },

    saveMissing: true,
    missingKeyHandler(lng, ns, key, fallbackValue) {

      if (!missing[ns]) {
        missing[ns] = {}
      }

      missing[ns][key] = fallbackValue

      if (missingTimeout) {
        clearTimeout(missingTimeout)
      }

      missingTimeout = setTimeout(() => {
        axios.post(`${API_URL}/fix/translations`, {
          namespaces: missing,
        })
      }, 5000)

      // console.log('%c Missing Key:', 'font-weight: bold; color: red', `${lng} - ${ns} - ${key}`)
    },

    lng: 'en',
    nonExplicitWhitelist: false,
    fallbackLng: 'en',
    debug: false,
    load: 'languageOnly',
    ns: [
      'common_volunteering',
      'header_volunteering',
      'footer_volunteering',
      'home_volunteering',
      'user_volunteering',
      'missionSearch_volunteering',
      'themes_volunteering',
      'profilePage_volunteering',
      'mission_volunteering',
      'chat_volunteering',
      'missionCreation_volunteering',
      'booking_volunteering',
    ],
    defaultNS: 'common_volunteering',
    keySeparator: false,

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },

    react: {
      wait: true,
      useSuspense: false,
    },
  })

export default i18next
